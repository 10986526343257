import {GraphQLClient} from "graphql-request";
import {system} from "../constants";
import {AbstractServices} from "@cosmos/chaos";

export class ProductService extends AbstractServices {
    initClient() {
        return new GraphQLClient(system.endpoint);
    }

    async getAllProducts() {
        const query = `query getProducts($filter: ProductFilter) {
            products(filter: $filter) {
                id
                name
                description
                brandID
                reference
                dialColor
                gems
                gender
                style
                feature
                SKU
                source
                updatedAt
            }
        }`;

        let results = await this.client.request(query);

        return results.products.map(p=>{
            p.id = parseInt(p.id);
            return p;
        });
    }

    async getProduct(filter) {
        const variables = {
            filter: {
                id: Number(filter.id) || null
            }
        };
        const query = `query getProduct($filter: ProductFilter){
        product(filter: $filter){
            id
            brandID
            modelID
            name
            reference
            dialColor
            gems
            waterResistance
            bundled
            limited
            gender
            style
            related
            feature
            manufacturedFrom
            manufacturedTo
            box
            SKU
            case
                {
                    materials
                    crystal
                    bezel
                    back
                    diameter
                    height
                    lugWidth
                }
            dial
                {
                    color
                    indexType
                    handStyle
                    subDial
                    gemSet
                    finish
                }
            caliber
                {
                    type
                    description
                    brand
                    reserve
                    reference
                    diameter
                    frequency
                    jewels
                    chronograph
                    hands
                    display
                    additional
                }
            band
                {
                    type
                    materials
                    buckle
                }   
            description
            search
            path
            external
                {
                    official
                    kakaku
                    hh
                    watchbase
                    jomashop
                    prestigetime
                    watchmaxx
                    watchesofmayfair
                    verified
                    verifiedBy
                }
            brand
                {       
                    name
                }
            assets 
                {
                    type
                    reference
                }
          }
          channelProduct(filter:$filter, channel: "ws") {
            description
        }
      }`;
        let results = await this.client.request(query, variables);

        let product = results.product;
        product.channelDescription = results.channelProduct.description;

        // by default it checks isIdentity
        return results.product;
    }

    async createProduct(product) {
        const query = ` mutation createProductMutation($input: ProductInput){ 
            createProduct(input: $input){
                id
            }
        }`;
        if (product.shipping) {
            //later will have to save it in anima?
            delete product.shipping;
        }
        const variables = {
            input: product
        };

        let results = await this.client.request(query, variables);
        return results.createProduct;
    }

    async updateProduct(productInput) {
        if (productInput.brand) {
            //later will resolve
            delete productInput.brand;
        }
        if (productInput.shipping) {
            //later will have to save it in anima?
            delete productInput.shipping;
        }

        const query = `mutation updateProductMutation($input: ProductInput){ 
            updateProduct(input: $input){
                id
                name
            }
        }`;
        productInput.id = Number(productInput.id)
        delete productInput.assets

        if(productInput.related && productInput.related.length>0){
            productInput.related = productInput.related.map(el =>Number(el))
        }
        const variables = {
            input: productInput
        };

        let results = await this.client.request(query, variables);
        return results.updateProduct;
    }

    async updateChannelProduct(product, payload) {
        const query = `mutation updateProduct($product:ProductInput) {
            updateChannelProduct(input: $product, channel: "ws"){id reference}
        }`;

        const variables = {
            product: {
                id: parseInt(product.id),
                description: payload.description
            }
        };
        console.log(variables);
        let result = await this.client.request(query, variables);
        return result.updateChannelProduct;
    }

    async updateProducts(data) {
        const query = `mutation productBatchUpdate($input: ProductInput, $products: [Int]) {
            updateProducts(input: $input, products: $products){
                id
                name
            }
        }`;
        const {id, ...input} = data;
        const variables = {
            input: input,
            products: id
        };
        let results = await this.client.request(query, variables);
        return results.updateProducts;
    }

    async updateExRef(productInput) {
        const query = ` mutation updateProductMutation($input: ProductInput){ 
        updateProduct(input: $input){
                id
            }
        }`;
        const variables = {
            input: productInput
        };

        let results = await this.client.request(query, variables);
        return results.updateProduct;
    }

    async updateCaliberInfo(product, WatchMovementInput) {
        const query = ` mutation updateCaliberInfoMutation($product: Int,$input: WatchMovementInput){ 
                updateCaliberInfo(product: $product,input: $input){
                    id
                }
            }`;

        const variables = {
            product: product,
            input: WatchMovementInput
        };

        let results = await this.client.request(query, variables);
        return results.updateCaliberInfo;
    }

    async updateDialInfo(product, WatchDialInput) {
        const query = ` mutation updateCaliberInfoMutation($product: Int,$input: WatchMovementInput){ 
                updateDialInfo(product: $product,input: $input){
                    id
                }
            }`;

        const variables = {
            product: product,
            input: WatchDialInput
        };

        let results = await this.client.request(query, variables);
        return results.updateDialInfo;
    }

    async updateBandInfo(product, WatchBandInput) {
        const query = ` mutation updateBandInfoMutation($product: Int,$input: WatchMovementInput){ 
                updateBandInfo(product: $product,input: $input){
                    id
                }
            }`;
        const variables = {
            product: product,
            input: WatchBandInput
        };

        let results = await this.client.request(query, variables);
        return results.updateBandInfo;
    }

    async updateCaseInfo(product, WatchCaseInput) {
        const query = ` mutation updateBandInfoMutation($product: Int,$input: WatchMovementInput){ 
                updateCaseInfo(product: $product,input: $input){
                    id
                }
            }`;
        const variables = {
            product: product,
            input: WatchCaseInput
        };

        let results = await this.client.request(query, variables);
        return results.updateCaseInfo;
    }

    async updateSalesInfo(product, SKUInput) {
        const query = ` mutation updateSKUMutation($product: Int,$input: WatchSKUInput){ 
                updateSKU(product: $product,input: $input){
                    id
                }
            }`;
        const variables = {
            product: product,
            input: SKUInput
        };

        let results = await this.client.request(query, variables);
        return results.updateSKU;
    }
}
