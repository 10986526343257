import React, { Fragment } from "react";
import { Field } from "formik";
import { flattenObject } from "../../utils";
import { indigo } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import WatchIcon from "@material-ui/icons/Watch";
import {
  Checkbox,
  Select,
  FormControlLabel,
  TextField,
  MenuItem,
  InputLabel,
  Chip,
  Button,
  Typography,
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";

import { FieldArray } from "formik";

import {SmartBox} from "@cosmos/chaos";

const styles = theme => ({
  icon: {
    color: green[800]
  },
  saleswrap: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  }
});

export const FormikTextField = ({
  field,
  form: { touched, errors },
  ...props
}) => <TextField {...field} {...props} />;

export const CustomCheckboxComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...rest
}) => (
  <Fragment>
    <FormControlLabel
      control={<Checkbox {...field} {...rest} />}
      label={rest.label}
      // value={value ? this.state.true : this.state.false}
    />
  </Fragment>
);

export const CustomSelectGenderComponent = ({
  field, // { name, value, onChange, onBlur }
  classes,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Fragment>
    <InputLabel htmlFor="gender" className={classes.formGenderControl}>
      Gender
    </InputLabel>

    <Select
      {...field}
      {...props}
      inputProps={{ id: "gender" }}
      className={classes.formGenderControl}
    >
      <MenuItem value="F">Lady</MenuItem>
      <MenuItem value="M">Gentlemen</MenuItem>
      <MenuItem value="unisex">Unisex</MenuItem>
    </Select>
  </Fragment>
);

export const CustomTextAreaComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => <TextField {...field} {...props} multiline={true} rowsMax="8" />;

export class CustomChipComponent extends React.Component {
  state = {
    word: "",
    error: ""
  };
  handleChange = e => {
    if (
      this.props.name === "related" &&
      Number.isInteger(Number(e.target.value))
    ) {
      this.setState({ word: e.target.value });
    } else if (this.props.name !== "related") {
      this.setState({ word: e.target.value });
    } else {
      // this.setState(theState =>({ ...theState, error: "kindly submit only Integer values"}))
    }
  };
  resetState = () => {
    this.setState({ word: "" });
  };

  render() {
    const { name, value, label, classes } = this.props;

    // console.log("props chip field array", this.props);
    return (
      <Fragment>
        <FieldArray
          name={name}
          render={arrayHelpers => (
            <div>
              <TextField
                // name={name}
                // component={FormikTextField}
                label={label}
                onChange={this.handleChange}
                onKeyPress={e => {
                  if (e.key === "Enter" && this.state.word !== "") {
                    arrayHelpers.unshift(this.state.word);
                    this.resetState();
                  }
                }}
                value={this.state.word}
                helperText={
                  this.props.name === "related" ? "only integers please" : null
                }
                className={classes.formField}
              />
              <Button
                color="primary"
                onClick={() => {
                  if (this.state.word === "") {
                    return;
                  }
                  arrayHelpers.unshift(this.state.word);
                  this.resetState();
                }}
              >
                Add
              </Button>

              {value &&
                value.length > 0 &&
                value.map((title, index) => {
                  let icon = null;
                  return (
                    <React.Fragment key={label + index}>
                      <Field
                        name={`${name}.${index}`}
                        component={Chip}
                        icon={icon}
                        label={title}
                        onDelete={() => arrayHelpers.remove(index)}
                      />
                    </React.Fragment>
                  );
                })}
            </div>
          )}
        />
      </Fragment>
    );
  }
}

export let BrandDownShift = () => <SmartBox />;

const CustomTableCell = withStyles(theme => {
  // console.log("theme", theme);
  return {
    head: {
      backgroundColor: indigo[500],
      color: theme.palette.common.white
    },
    body: {
      fontSize: 22
    }
  };
})(TableCell);
export const DialogContentTouched = ({ values, touched, product, classes }) => {
  let ob = flattenObject(touched);
  let ob1 = flattenObject(values);
  let ob2 = flattenObject(product);
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCell>Product Field name</CustomTableCell>
            <CustomTableCell>Modified Field</CustomTableCell>
            <CustomTableCell>Before Modification</CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(ob).map((el, index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>
                    <Typography variant="display2">
                      <InputLabel>{el}</InputLabel>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="display2">
                      <InputLabel>{ob1[el]}</InputLabel>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="display2">
                      <InputLabel>{ob2[el]}</InputLabel>
                    </Typography>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

const stylesImages = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    height: "480px"
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    // transform: "translateZ(0)",
    height: "480px",
    position: "absolute"
  },
  gridListTile: {
    margin: "auto",
    objectFit: "contain",
    width: "100%",
    height: "100%"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  }
});

class SingleLineGridList extends React.Component {
  state = {
    currentImage: 0,
    pages: 0,
    count: 0
  };

  handleClick = index => {
    this.setState({ currentImage: index });
  };

  render() {
    const { classes, watchesImages } = this.props;

    // console.log("this", this);
    return (
      <div className={classes.root}>
        {/* <GridList className={classes.gridList} cols={1} cellHeight={400}>
          {watchesImages.length > 0 &&
            watchesImages.map(
              (tile, index) =>
                index === this.state.currentImage ? (
                  <GridListTile
                    key={tile.reference}
                    className={classes.gridListTile}
                  >
                    <img
                      src={`https://synopsis.cosmos.ieplsg.com/files/${
                        tile.reference
                      }`}
                      alt={tile.type}
                    />
                  </GridListTile>
                ) : null
            )}
        </GridList> */}
        <div>
          <div>hi</div>
          <div className={classes.gridList}>
            {watchesImages.length > 0 &&
              watchesImages.map((tile, index) => {
                let condition = index === this.state.currentImage;

                return (
                  <img
                    key={tile.reference}
                    src={`https://synopsis.cosmos.ieplsg.com/files/${
                      tile.reference
                    }`}
                    className={condition ? classes.gridListTile : null}
                  />
                );
              })}
          </div>
          <div>hi</div>
        </div>
        <div style={{ bottom: "50px" }}>
          {watchesImages.length > 0 &&
            watchesImages.map((tile, index) => {
              let condition = index === this.state.currentImage;
              console.log("condition", condition);
              return (
                <IconButton
                  key={index + tile.reference}
                  disabled={condition ? true : false}
                  onClick={() => this.handleClick(index)}
                >
                  <WatchIcon />
                </IconButton>
              );
            })}
        </div>
      </div>
    );
  }
}

export const ImagesMui = withStyles(stylesImages)(SingleLineGridList);
