import React from 'react'

import {Formik, Field,} from 'formik'
import {CardContent, Chip, InputAdornment, TextField, Typography} from "@material-ui/core";
import {SmartBox} from '@cosmos/chaos'

const TextFieldComponent = ({form, field, ...props}) => {

    return (
        <TextField {...field} {...props}/>
    )

}

let chipRenderer = (props) => {
    const {item, handleDelete} = props;
    console.log("item", item);
    return (
        <Chip
            key={item}
            tabIndex={-1}
            label={item}
            onDelete={handleDelete(item)}
        />
    );
}
export const BandContent = ({field, initialData, render,title}) => {


    return <Formik
        initialValues={initialData}
        enableReinitialize
        render={({values,setFieldValue,dirty}) => {

            return (
                <div>
                    <Typography variant={"headline"}>
                        {title}
                    </Typography>
                    <Field
                        component={TextFieldComponent}
                        name={field + ".type"}
                        label="Type"
                        fullWidth
                        margin="dense"
                    />

                    <SmartBox
                        isOpen={false}
                        multi
                        updateSearchText={(word) => setFieldValue(field+".edit.materials",word)}
                        searchText={values[field].edit.materials}
                        suggestionProvider={(e) => [e]}
                        selectedItems={values[field].materials}
                        updateSelectedItems={(items)=> setFieldValue(field+".materials",items)}
                        chipRenderer={chipRenderer}
                        placeHolder="Materials"
                    />

                    <Field
                        component={TextFieldComponent}
                        name={field + ".buckle"}
                        label="Buckle"
                        fullWidth
                        margin="dense"
                    />
                    {render({values: values[field], field,dirty})}
                </div>
            )
        }
        }
    />
}