import React from "react";
import {Route, Switch} from "react-router";
import Navigation from "../../components/navigation";
import Products from "../products";
import {PrivateRoute, Login} from "@cosmos/chaos";

class App extends React.Component {
  render() {
    return (
      <>
        <Navigation />
          <Switch>
            <Route exact path="/login" component={Login}/>
            <PrivateRoute exact path="/" component={Products}/>
          </Switch>
      </>
    );
  }
}
export default App;
