import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {updateBrandFilter} from "../../actions";

import styles from './brand.filter.module.scss';
import Chip from "@material-ui/core/Chip/Chip";

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    handleChange = event => {
        this.props.dispatch(updateBrandFilter(event.target.value));
    };

    render() {
        const {selected, brands} = this.props;

        return (
            <div className={styles.root}>
                <FormControl className={styles.form}>
                    <InputLabel className={styles.label} classes={{shrink: styles.shrink}}>
                        Brand
                    </InputLabel>
                    <Select
                        autoWidth
                        multiple
                        onChange={this.handleChange}
                        renderValue={() => (
                            <div className={styles['chip-bar']}>
                                {selected.map(s=>(<Chip className={styles.chip} label={brands[s]} variant="outlined"/>))}
                            </div>
                        )}
                        value={selected}
                        className={styles['select-ctrl']}
                    >
                        {Object.keys(brands).map(brand => (
                            <MenuItem className={styles['menu-item']} key={brand} value={brand}>
                                <Checkbox checked={selected.indexOf(brand) > -1}/>
                                <ListItemText className={styles['menu-item-text']} primary={brands[brand]}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        )
    }
}

export const BrandFilter = connect(state => ({
    brands: state.products.brands,
    selected: state.products.brandFilter
}))(Header);
