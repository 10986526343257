import React from "react";
import {
    AppBar,
    Toolbar,
    Typography
} from "@material-ui/core";
import {LanguageSelection} from "./languageComponent/language";
import Button from "@material-ui/core/Button/Button";
import UserIcon from '@material-ui/icons/PersonSharp';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import {connect} from 'react-redux';
import {AUTH_LOGOUT} from '@cosmos/chaos';

function Navigation(props) {
    const logout = (event)=>{
        props.dispatch({type: AUTH_LOGOUT});
    };
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h5" color="inherit">
                    Cosmos Synopsis
                </Typography>
                <span style={{flex: 1}}/>
                {/*<LanguageSelection />*/}
                <UserIcon/>
                <Typography variant="h6" color="inherit">
                    {props.username}
                </Typography>
                <Button color="inherit" onClick={logout}>
                    <LogoutIcon/>
                </Button>
            </Toolbar>
        </AppBar>
    );
}

export default connect(state=>({
  username: state.auth.username
}))(Navigation);
