import React, { Fragment } from "react";

import {
  Typography,
  InputAdornment,
  Card,
  CardContent
} from "@material-ui/core";
import { Field } from "formik";
import { FormikTextField } from "../customFields";
import DoneIcon from "@material-ui/icons/Done";
import SearchIcon from "@material-ui/icons/Search";
export class ReferenceInfoCard extends React.Component {
  state = {
    verified: true
  };

  render() {
    const { values, classes } = this.props;
    return (
      <Card className={classes.contentCard}>
        <CardContent>
          <Typography variant="headline">Reference Info</Typography>
          <Field
            component={FormikTextField}
            label="Official Site"
            name={"external.official"}
            fullWidth
            margin="dense"
            id="official"
            InputProps={{
              startAdornment: values.verified ? (
                <InputAdornment position="start">
                  <DoneIcon className={classes.icon} />
                </InputAdornment>
              ) : (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Field
            component={FormikTextField}
            label="KAKAKU"
            name={"external.kakaku"}
            fullWidth
            margin="dense"
            id="kakaku"
            InputProps={{
              startAdornment: values.verified ? (
                <InputAdornment position="start">
                  <DoneIcon className={classes.icon} />
                </InputAdornment>
              ) : (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Field
            component={FormikTextField}
            label="Watch Base"
            name={"external.watchbase"}
            fullWidth
            margin="dense"
            id="watchbase"
            InputProps={{
              startAdornment: values.verified ? (
                <InputAdornment position="start">
                  <DoneIcon className={classes.icon} />
                </InputAdornment>
              ) : (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Field
            component={FormikTextField}
            label="HH"
            name={"external.hh"}
            fullWidth
            margin="dense"
            id="hh"
            InputProps={{
              startAdornment: values.verified ? (
                <InputAdornment position="start">
                  <DoneIcon className={classes.icon} />
                </InputAdornment>
              ) : (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Field
            component={FormikTextField}
            label="JomaShop"
            name={"external.jomashop"}
            fullWidth
            margin="dense"
            id="jomashop"
            InputProps={{
              startAdornment: values.verified ? (
                <InputAdornment position="start">
                  <DoneIcon className={classes.icon} />
                </InputAdornment>
              ) : (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Field
            component={FormikTextField}
            label="VIP Station"
            name={"external.vip"}
            fullWidth
            margin="dense"
            id="vip"
            InputProps={{
              startAdornment: values.verified ? (
                <InputAdornment position="start">
                  <DoneIcon className={classes.icon} />
                </InputAdornment>
              ) : (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </CardContent>
      </Card>
    );
  }
}
