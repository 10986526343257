import React from "react";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import { connect } from "react-redux";
import classNames from 'classnames';

class BrandBox extends React.Component {
  render() {
    const { brandID, styles = {}, variant } = this.props;
    const brandName = this.props.brands[brandID];
    return (
      <div className={classNames(styles.root)}>
        <Typography variant={variant} className={styles.title}>{brandName}</Typography>
      </div>
    );
  }
}

export default connect(state => ({
    brands: state.products.brands
  }))(BrandBox);
