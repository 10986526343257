import {
    BRAND_LOAD_SUCCESS,
    PRODUCT_EDIT_CANCEL,
    PRODUCT_EDIT_SINGLE,
    PRODUCT_LOAD_BEGIN,
    PRODUCT_LOAD_FAILURE,
    PRODUCT_LOAD_SUCCESS,
    PRODUCT_EDIT_BATCH,
    PRODUCT_SINGLE_CLONE,
    PRODUCT_FILTERED,
    PRODUCT_SHIFT,
    SEARCH_UPDATED,
    BRAND_FILTER_UPDATED,
    SELECTED_PRODUCTS,
    PRODUCT_REDUX_UPDATE, PRODUCT_UPDATE_SUCCESS, PRODUCT_EDIT_CHANNEL
} from "../actions";

const initialState = {
    loading: false,
    error: null,
    dialog: false,
    multi: false,
    target: null,
    data: [],
    brands: [],
    clone: false,
    filtered: [],
    selected: [],
    brandFilter: [],
    searchText: "",
    ids: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_REDUX_UPDATE:
            return {
                ...state,
                data: action.payload
            };
        case PRODUCT_SHIFT:
            return {
                ...state,
                target: action.payload
            };
        case PRODUCT_FILTERED:
            return {
                ...state,
                filtered: action.payload
            };
        case SELECTED_PRODUCTS:
            return {
                ...state,
                selected: action.payload
            };
        case PRODUCT_EDIT_BATCH:
            return {
                ...state,
                ids: action.payload,
                multi: true,
                clone: false,
                dialog: true,
                target: {id: -1}
            };
        case PRODUCT_SINGLE_CLONE:
            return {
                ...state,
                target: action.payload,
                dialog: true,
                multi: false,
                clone: true
            };
        case BRAND_LOAD_SUCCESS:
            return {
                ...state,
                brands: action.payload.brands,
                downshiftBrand: action.payload.result
            };
        case PRODUCT_LOAD_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case PRODUCT_LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.products,
                filtered: action.payload.products
            };
        case PRODUCT_LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: [],
                filtered: []
            };
        case PRODUCT_EDIT_SINGLE:
            return {
                ...state,
                dialog: true,
                multi: false,
                target: action.payload,
                clone: false
            };
        case PRODUCT_EDIT_CANCEL:
            return {
                ...state,
                dialog: false,
                multi: false,
                target: null,
                batch: null,
                ids: [],
                clone: false
            };
        case SEARCH_UPDATED:
            return {
                ...state,
                searchText: action.payload
            };
        case BRAND_FILTER_UPDATED:
            return {
                ...state,
                brandFilter: action.payload
            };
        case PRODUCT_UPDATE_SUCCESS:
            return {
                ...state,
                dialog: true,
                target: null
            };
        default:
            return state;
    }
};
