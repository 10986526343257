import React from "react";
import {
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  AppBar,
  Toolbar
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { shiftProduct } from "../../../../../actions";

export const AppBarDialog = ({
  clone,
  values,
  product,
  classes,
  ids,
  checkChevron,
  dispatch
}) => {
  return (
    <AppBar className={product ? classes.appBar : classes.appBarBatchMode}>
      {/* in case we will use the dialog to warn to save the touched fields */}
      {/* <Dialog
          open={chevronDialogOpen ? true : false}
          onClose={() => props.dispatch(closeChevronDialog())}
          maxWidth="sm"
        >
          <DialogContent>
            <DialogContentTouched
              values={values}
              touched={touched}
              product={product}
            />
            <DialogActions>
              <Button
                onClick={async () => {
                  handleSubmit();
                  props.dispatch(closeChevronDialog());
                  props.dispatch(shiftProduct(product.id, chevronDialogOpen));
                }}
              >
                <Typography color="primary">
                  SUBMIT changes and move on to next Product
                </Typography>
              </Button>
              <Button
                onClick={() => {
                  props.dispatch(closeChevronDialog());
                  props.dispatch(shiftProduct(product.id, chevronDialogOpen));
                }}
              >
                <Typography className={classes.warning}>
                  move on to next product WITHOUT SAVING
                </Typography>
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog> */}
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={() => {
            // Open dialog to make sure user wants to proceed without saving data
            // if (!isEmpty(touched) && props.submitCount == 0) {
            //   props.dispatch(openChevronDialog(-1));
            //   return;
            // }
            dispatch(shiftProduct(product.id, -1));
          }}
          disabled={checkChevron(product.id).left}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="title" color="inherit">
          {product && product.id > 0
            ? `Product: ${product.id} - ${product.reference}`
            : ids.length > 0 && !clone
              ? `Batch Edit Products ${ids}`
              : clone
                ? `New Product: reference ${product.reference}`
                : "New product"}
        </Typography>
        <IconButton
          color="inherit"
          onClick={() => {
            //Open dialog to make sure user wants to proceed without saving data
            // if (!isEmpty(touched) && props.submitCount == 0) {
            //   // console.log(touched);
            //   props.dispatch(openChevronDialog(1));
            //   return;
            // }
            dispatch(shiftProduct(product.id, 1));
          }}
          disabled={checkChevron(product.id).right}
        >
          <ChevronRightIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
