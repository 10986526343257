import React from 'react'

import {Formik, Field,} from 'formik'
import {InputAdornment, TextField, Typography} from "@material-ui/core";


const TextFieldComponent = ({form, field, ...props}) => {

    return (
        <TextField {...field} {...props}/>
    )

}
const TextAreaComponent = ({form, field, ...props}) => {
    return <TextField {...field} {...props} multiline rows={8} rowsMax={15}/>
}

export const CaliberContent = ({field, initialData, render,title}) => {


    return <Formik
        initialValues={initialData}
        enableReinitialize
        render={({values,dirty}) => {

            return (
                <div>
                    <Typography variant={"headline"}>
                        {title}
                    </Typography>
                    <Field
                        component={TextFieldComponent}
                        name={field + ".type"}
                        label="Type"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextAreaComponent}
                        name={field + ".description"}
                        label="Description"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".brand"}
                        label="Brand"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".reserve"}
                        label="Reserve"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".reference"}
                        label="Reference"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".diameter"}
                        label="Diameter"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".frequency"}
                        label="Frequency"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".jewels"}
                        label="Jewels"
                        fullWidth
                        margin="dense"
                    />

                    <Field
                        component={TextFieldComponent}
                        name={field + ".chronograph"}
                        label="Chronograph"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".hands"}
                        label="Hands"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".display"}
                        label="Display"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".additional"}
                        label="Additional"
                        fullWidth
                        margin="dense"
                    />
                    {render({values: values[field], field,dirty})}
                </div>
            )
        }
        }
    />
}
