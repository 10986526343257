import React from "react";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import {
  cancelEdit,
  updateProducts,
  updateProduct,
  shiftProduct,
  createProduct,
  openChevronDialog,
  closeChevronDialog
} from "../../../actions";
import {
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Card,
  Button,
  IconButton,
  AppBar,
  Toolbar,
  CardContent
} from "@material-ui/core";
import {
  GeneralCard,
  DialCard,
  BandCard,
  CaseCard,
  ReferenceInfoCard,
  SalesCard,
  CaliberCard,
  ShippingCard,
  AppBarDialog
} from "./custom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { defaultsDeep } from "lodash";
import { productSchema } from "../productSchema";
const styles = theme => ({
  appBar: {
    position: "relative"
  },
  appBarBatchMode: {
    position: "relative",
    backgroundColor: "red"
  },
  contentBody: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    height: "5600px"
  },
  contentCard: {
    margin: theme.spacing.unt
  },
  generalCard: {
    display: "flex"
  },
  generalCard2: {
    display: "flex",
    flexDirection: "column"
  },
  productViewer: {
    width: "480px"
  },
  productMedia: {
    margin: "auto",
    objectFit: "contain",
    width: "100%",
    height: "100%"
  },
  formRow: {
    display: "flex",
    justifyContent: "space-between"
  },
  formRow2: {
    display: "flex",
    justifyContent: "flex-start"
  },
  formGenderControl: {
    margin: theme.spacing.unit,
    maxWidth: 150
  },
  formField: {
    margin: theme.spacing.unit
  },
  warning: {
    backgroundColor: theme.palette.warning
  }
});

const formikEnhancer = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    if (props.product) {
      if (Array.isArray(props.product)) {
        return {};
      } else {
        let initialValues = defaultsDeep(props.product, productSchema);

        return { ...initialValues };
      }
    } else {
      return { id: null };
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    console.log("SUBMITTING");
    setSubmitting(true);
    let submit;

    //create cloned Product
    if (props.clone && props.product.id === -1) {
      console.log("cloning submitted");
      submit = createProduct(values);
    }
    //create product cloned or not
    if (!props.multi && !props.clone && props.product && props.product.id < 0) {
      console.log("creation submitted");
      submit = createProduct(values);
    }
    //batchEditing --updateBatchProduct
    if (props.multi && props.ids.length > 0 && props.product.id < 0) {
      console.log("update batch submitted");
      submit = updateProducts({ ...values, id: props.ids });
    }
    //update product
    if (props.product && props.product.id > 0) {
      console.log("update submitted");
      submit = updateProduct(values);
    }

    await props.dispatch(submit);
    setSubmitting(false);
  },
  displayName: "ProductLanguageEditor"
});

const Editor = props => {
  const {
    classes,
    product,
    dialogOpen,
    multi,
    values,
    touched,
    errors,
    dirty,
    isSubmiting,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    downshiftBrand,
    brands,
    ids,
    clone,
    chevronDialogOpen,
    products,
    setValues,
    language,
    dispatch
  } = props;

  const dialogOnClose = () => {
    props.dispatch(cancelEdit());
  };
  const checkChevron = id => {
    let count = products.length - 1;
    let index;
    if (id < 0) {
      products.some((el, now) => {
        if (el.id === id) {
          index = now;
        }
      });
    }
    return {
      left: id < 0 ? true : index === 0 ? true : false,
      right: id < 0 ? true : count === index ? true : false
    };
  };

  return (
    <Dialog open={dialogOpen} onClose={dialogOnClose} maxWidth="md">
      <AppBarDialog
        {...props}
        checkChevron={checkChevron}
        dispatch={dispatch}
      />
      <DialogContent className={classes.contentBody}>
        <GeneralCard {...props} />
        {language && <BandCard classes={classes || {}} values={values} />}
      </DialogContent>

      <DialogActions>
        <Button onClick={dialogOnClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!dirty || isSubmiting}
          color="primary"
        >
          {values.id > 0
            ? "Update"
            : ids.length > 0 && !clone
              ? `Batch Edit- ${ids}`
              : clone
                ? `create from reference ${product.reference}`
                : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ProductLanguageEditor = withStyles(styles)(
  connect(state => ({
    product: state.products.target,
    dialogOpen: state.products.dialog,
    multi: state.products.multi,
    brands: state.products.brands,
    downshiftBrand: state.products.downshiftBrand
      ? Object.keys(state.products.brands).map(el => state.products.brands[el])
      : [],
    ids: state.products.ids,
    clone: state.products.clone,
    chevronDialogOpen: state.products.chevronDialogOpen,
    products: state.products.filtered,
    language: state.productsLanguage.language
  }))(formikEnhancer(Editor))
);
