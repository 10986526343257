import {BrandService} from "./brands";
import {ProductService} from "./products";

class ServiceCenter {
    constructor() {
        this.services = {
            brand: new BrandService(),
            product: new ProductService()
        }
    }

    registerServices = (store)=>{
        store.subscribe(()=>{
            Object.keys(this.services).map(
                (k)=>this.services[k].tokenAdaptor(store.getState())
            )
        })
    };

    get brand(){
        return this.services.brand;
    }

    get product() {
        return this.services.product;
    }
}


export const Services = new ServiceCenter();
