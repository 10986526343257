import * as React from "react";
import {connect} from 'react-redux';
import styles from './channelProductEditor.module.scss';
import Dialog from "@material-ui/core/Dialog/Dialog";
import {cancelEdit, updateChannelProduct} from "../../../actions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import Card from "@material-ui/core/Card/Card";
import Slider from "../../slider";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import {TextField} from "@material-ui/core";
import BrandBox from '../../brandbox';
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Chip from "@material-ui/core/Chip/Chip";
import ClassNames from 'classnames';

class Editor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            description: null,
            prepare: true
        }
    }

    dialogOnClose = () => {
        this.props.dispatch(cancelEdit())
    };

    updateChannelDescription = (event) => {
        this.setState({description: event.target.value});
    };

    rework = () => {
        this.setState({prepare: true});
    };

    prepareUpdate = () => {
        this.setState({prepare: false});
    };

    submitUpdate = () => {
        this.props.dispatch(updateChannelProduct({description: this.state.description}));
    };

    render() {
        const {dialogOpen, product} = this.props;
        const {description, prepare} = this.state;

        return (
            <Dialog className={styles.dialog}
                    open={dialogOpen}
                    onClose={this.dialogOnClose}
                    scroll="body"
                    maxWidth="lg">
                <AppBar className={styles.header}>
                    <Toolbar>
                        <BrandBox brandID={product.brandID} variant="h5"/>
                        <Typography variant="h5"> - {product.reference}</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent className={styles.content}>
                    <Card className={styles["info-panel"]}>
                        <div className={styles.section}>
                            <Typography className={styles.title} variant="body1">General</Typography>
                            <List>
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Brand
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <BrandBox brandID={product.brandID} variant="body1"/>
                                    </div>
                                </ListItem>
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Reference
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.reference}
                                        </Typography>
                                    </div>
                                </ListItem>
                                {product.name &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Name
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.name}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.color &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Color
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.color}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.gems &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Gems
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.gems}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.style &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Style
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.style}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.feature &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Features
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        {product.feature.map((f, idx) => (
                                            <Chip key={idx} label={f}/>
                                        ))}
                                    </div>
                                </ListItem>}
                            </List>
                        </div>
                        {product.dial &&
                        <div className={styles.section}>
                            <Typography className={styles.title} variant="body1">Dial</Typography>
                            <List>
                                {product.dial.color &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Color
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.dial.color}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.dial.indexType &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Index Type
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.dial.indexType}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.dial.handStyle &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Hand Style
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.dial.handStyle}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.dial.subDial &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Sub Dial
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.dial.subDial}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.dial.gemSet &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Gem Set
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.dial.gemSet}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.dial.finish &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Finish
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.dial.finish}
                                        </Typography>
                                    </div>
                                </ListItem>}
                            </List>
                        </div>
                        }
                        {product.case &&
                        <div className={styles.section}>
                            <Typography className={styles.title} variant="body1">Case</Typography>
                            <List>
                                {product.case.materials &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Materials
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.case.materials}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.case.shape &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Shape
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.case.shape}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.case.crystal &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Crystal
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.case.crystal}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.case.bezel &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Bezel
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.case.bezel}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.case.back &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Back
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.case.back}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.case.diameter &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Diameter
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.case.diameter}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.case.height &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Height
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.case.height}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.case.lugWidth &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Lug Width
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.case.lugWidth}
                                        </Typography>
                                    </div>
                                </ListItem>}

                            </List>
                        </div>
                        }
                        {product.caliber &&
                        <div className={styles.section}>
                            <Typography className={styles.title} variant="body1">Caliber</Typography>
                            <List>
                                {product.caliber.type &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Type
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.caliber.type}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.caliber.description &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Description
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.caliber.description}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.caliber.brand &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Brand
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.caliber.brand}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.caliber.reserve &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Reserve
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.caliber.reserve}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.caliber.reference &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Reference
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.caliber.reference}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.caliber.diameter &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Diameter
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.caliber.diameter}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.caliber.frequency &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Frequency
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.caliber.frequency}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.caliber.jewels &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Jewels
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.caliber.jewels}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.caliber.chronograph &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Chronograph
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.caliber.chronograph}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.caliber.hands &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Hands
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.caliber.hands}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.caliber.display &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Display
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.caliber.display}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.caliber.additional &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Additional
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.caliber.additional}
                                        </Typography>
                                    </div>
                                </ListItem>}
                            </List>
                        </div>
                        }
                        {product.band &&
                        <div className={styles.section}>
                            <Typography className={styles.title} variant="body1">Band</Typography>
                            <List>
                                {product.band.type &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Type
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.band.type}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.band.materials &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Materials
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.band.materials}
                                        </Typography>
                                    </div>
                                </ListItem>}
                                {product.band.buckle &&
                                <ListItem className={styles.attribute}>
                                    <div className={styles.label}>
                                        <Typography variant="body1">
                                            Buckle
                                        </Typography>
                                    </div>
                                    <div className={styles.value}>
                                        <Typography variant="body1">
                                            {product.band.buckle}
                                        </Typography>
                                    </div>
                                </ListItem>}
                            </List>
                        </div>
                        }
                    </Card>
                    <Card className={styles["edit-panel"]}>
                        <div>
                            <div className={ClassNames(styles.section, styles.reference)}>
                                <Typography className={styles.title} varient="body1">
                                    Description (Reference)
                                </Typography>
                                <Typography variant="body1">
                                    {product.description}
                                </Typography>
                            </div>
                            {product.assets &&
                            <div className={styles['product-viewer']}>
                                {product.assets.length > 0 && (
                                    <Card>
                                        <Slider assets={product.assets}/>
                                    </Card>
                                )}
                            </div>
                            }
                        </div>
                        <Card className={styles["target-form"]}>
                            <div>
                                {prepare ?
                                    <TextField
                                        label="Description"
                                        variant="filled"
                                        defaultValue={product.channelDescription}
                                        value={description}
                                        onChange={this.updateChannelDescription}
                                        multiline
                                        fullWidth
                                        rows={12}/> :
                                    (
                                        <div className={styles.prepared}>
                                            <Typography variant="h6" className={styles.title}>
                                                Description
                                            </Typography>
                                            <Typography variant="body1" className={styles.body}>
                                                {description}
                                            </Typography>
                                        </div>
                                    )
                                }
                            </div>
                        </Card>
                    </Card>
                </DialogContent>
                <DialogActions className={styles.actions}>
                    {prepare ?
                        (<>
                            <Button onClick={this.dialogOnClose} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={this.prepareUpdate} color="primary">
                                Submit
                            </Button>
                        </>) :
                        (<>
                            <Button onClick={this.rework} color="secondary">
                                Back
                            </Button>
                            <Button onClick={this.submitUpdate} color="primary">
                                Confirm
                            </Button>
                        </>)
                    }
                </DialogActions>
            </Dialog>
        )
    }
}


export const ChannelProductEditor = connect(
    state => ({
        brand: state.products.brand,
        product: state.products.target,
        dialogOpen: state.products.dialog
    })
)(Editor);
