import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {createBrowserHistory} from "history";
import {connectRouter, routerMiddleware} from 'connected-react-router';

import products from "./products";
import productsLanguage from "./productsLanguage";
import {AuthStore, loadAuthToken, refreshTokenMiddleware, saveAuthToken} from "@cosmos/chaos";
import thunk from "redux-thunk";
import {Services} from "../services";

export const history = createBrowserHistory();

const middleware = [
    refreshTokenMiddleware,
    thunk,
    routerMiddleware(history)];
const initialState = {...loadAuthToken('cosmos')};
const enhancers = [];
if (process.env.NODE_ENV === "development") {
    const devToolsExtension = window["devToolsExtension"];
    if (typeof devToolsExtension === "function") {
        enhancers.push(devToolsExtension());
    }
}

const rootReducer = combineReducers({
    router: connectRouter(history),
    auth: AuthStore,
    products: products,
    productsLanguage
});
const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers);

store.subscribe(()=>{
    saveAuthToken(store.getState());
});

Services.registerServices(store);

export default store;
