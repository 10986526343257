import {mapValues, keyBy} from "lodash";
import {Services} from "../services";
import {filterOutNonEmptyFields, concatUniq} from "./utils";

export const PRODUCT_LOAD_BEGIN = "@@product/LOAD_PRODUCT_BEGIN";
export const PRODUCT_LOAD_SUCCESS = "@@product/LOAD_PRODUCT_SUCCESS";
export const PRODUCT_LOAD_FAILURE = "@@product/LOAD_PRODUCT_FAILURE";

export const SEARCH_TEXT = "@@product/SEARCH_TEXT";
export const PRODUCT_EDIT_SINGLE = "@@product/EDIT_PRODUCT_SINGLE";
export const PRODUCT_EDIT_BATCH = "@@product/EDIT_PRODUCT_BATCH";
export const PRODUCT_EDIT_CANCEL = "@@product/EDIT_PRODUCT_CANCEL";

export const PRODUCT_UPDATE_SUCCESS = "@@product/UPDATE_SUCCESS";

export const PRODUCT_SINGLE_CLONE = "@@product/SINGLE_CLONE";
export const PRODUCT_FILTERED = "@@product/FILTERED";
export const PRODUCT_SELECTED_UPDATED = "@@product/SELECTED_UPDATE";
export const BRAND_LOAD_SUCCESS = "@@brand/LOAD_BRAND_SUCCESS";
export const PRODUCT_SHIFT = "@@product/SHIFT_PRODUCT_IN_MODAL";
export const PRODUCT_CHEVRON = "@@product/CHEVRON_DISABLE";
export const PRODUCT_REDUX_UPDATE = "@@product/PRODUCT_REDUX_UPDATE";

export const OPEN_CHEVRON_DIALOG = "@@product/CHEVRON_OPEN_DIALOG";
export const CLOSE_CHEVRON_DIALOG = "@@product/CHEVRON_CLOSE_DIALOG";

export const SELECTED_PRODUCTS = "@@product/SELECTED_PRODUCT";

export const SEARCH_UPDATED = "@@product/SEARCH_UPDATED";
export const BRAND_FILTER_UPDATED = "@@product/BRAND_FILTER_UPDATED";

export const fetchProductBegin = () => ({type: PRODUCT_LOAD_BEGIN});
export const fetchProductSuccess = products => ({
    type: PRODUCT_LOAD_SUCCESS,
    payload: {products}
});
export const fetchProductError = error => ({
    type: PRODUCT_LOAD_FAILURE,
    payload: {error}
});
export const fetchBrandSuccess = brands => ({
    type: BRAND_LOAD_SUCCESS,
    payload: brands
});
export const openChevronDialog = x => ({
    type: OPEN_CHEVRON_DIALOG,
    payload: x
});
export const closeChevronDialog = () => ({
    type: CLOSE_CHEVRON_DIALOG
});

export const selectProducts = selected => ({
    type: SELECTED_PRODUCTS,
    payload: selected
});

const productToShift = product => ({
    type: PRODUCT_SHIFT,
    payload: filterOutNonEmptyFields(product)
});
export const checkTotalBox = () => (dispatch, getState) => {
    let productData = getState().products.filtered.map(e => e);
    let selected = getState().products.selected.map(e => e);
    //no selection
    let selection;
    if (selected.length === 0) {
        selection = productData.map(p => p.id);
        dispatch(selectProducts(selection));
        return;
    }
    //select current table rows
    let newData = productData.map(p => p.id);

    //select

    let selectedData = newData.filter(id => selected.includes(id));
    let leftSelected = selected.filter(id => !newData.includes(id));

    //if new product table equals to the
    //filtered selected array then dispatch the let out
    // selected array which is not the current table selected rows
    //otherwise concat the selected and new table
    selection =
        newData.length === selectedData.length
            ? leftSelected
            : concatUniq([newData, selected]);

    dispatch(selectProducts(selection));
};

export const loadProducts = () => async dispatch => {
    try {
        dispatch(fetchProductBegin());
        let products = await Services.product.getAllProducts();
        dispatch(fetchProductSuccess(products));
    } catch (err) {
        fetchProductError(err);
    }
};

export const loadBrands = () => {
    return async dispatch => {
        let result = await Services.brand.getAllBrands();
        let brands = mapValues(keyBy(result, "id"), "name");
        dispatch(fetchBrandSuccess({brands, result}));
    };
};

export const editProduct = product => async (dispatch, getState) => {
    try {
        let result;
        if (product.id > 0) {
            result = await Services.product.getProduct({id: product.id});
        } else {
            result = product;
        }
        let filteredResult = filterOutNonEmptyFields(result);

        dispatch({type: PRODUCT_EDIT_SINGLE, payload: filteredResult});
    } catch (err) {
    }
};

export const editProducts = products => async dispatch => {
    //in case only one product is selected then fetchinformation
    if (products.length > 1) {
        dispatch({
            type: PRODUCT_EDIT_BATCH,
            payload: products
        });
    } else {
        dispatch(editProduct({id: products[0]}));
    }
};

export const cancelEdit = () => ({type: PRODUCT_EDIT_CANCEL});

export const updateProduct = productInput => async dispatch => {
    let result = await Services.product.updateProduct(
        filterOutNonEmptyFields(productInput)
    );
    dispatch({type: PRODUCT_UPDATE_SUCCESS, payload: result})
};

export const updateChannelProduct = payload => async (dispatch, getState) => {
    let product = getState().products.target;
    let result = await Services.product.updateChannelProduct(product, payload);
    dispatch({type: PRODUCT_UPDATE_SUCCESS});
};


export const cloneProduct = product => async dispatch => {
    let result;
    result = await Services.product.getProduct({id: product.id});
    result.id = -1;
    result = filterOutNonEmptyFields(result);

    dispatch({type: PRODUCT_SINGLE_CLONE, payload: result});
};

export const createProduct = product => async (dispatch, getState) => {
    product.id = -1;
    product = filterOutNonEmptyFields(product);
    try {
        let result = await Services.product.createProduct(product);
        product.id = result.id;
        //update redux state
        let products = JSON.parse(JSON.stringify(getState().products.data));
        dispatch({
            type: PRODUCT_REDUX_UPDATE,
            payload: products.concat(product)
        });
    } catch (err) {
    }
};

export const updateProducts = data => async dispatch => {
    try {
        let result = await Services.product.updateProducts(
            filterOutNonEmptyFields(data)
        );
    } catch (err) {
    }
};

export const shiftProduct = (id, counter) => async (dispatch, getState) => {
    let nextIndex;
    let nextId;
    let filtered = getState().products.filtered.map(e => e);
    let count = filtered.length - 1;
    filtered.some((el, index) => {
        if (el.id === id) {
            nextIndex = index;
        }
    });
    let condition = nextIndex + counter;

    if (condition >= 0 && condition <= count) {
        nextId = filtered[condition].id;
        let result = await Services.product.getProduct({id: Number(nextId)});
        dispatch(productToShift(result));
    }
};

// filter products
export const filterProducts = () => (dispatch, getState) => {
    // TODO New algo
    // Mark Filter stage updated or not
    // collect each stage
    // start from end leaf side
    // detect narrow or board
    // reuse last result

    let result = getState().products.data.map(e => e);

    const brandFilter = getState().products.brandFilter.map(b => parseInt(b));

    if (brandFilter.length > 0) {
        result = result.filter(product => {
            return brandFilter.indexOf(product.brandID) > -1;
        });
    }

    const searchText = getState().products.searchText;

    if (searchText && searchText.length > 2) {
        result = result.filter(product => {
            let result = false;
            const st = searchText.toLowerCase();
            const ref = product.reference ? product.reference.toLowerCase() : '';
            result = result || ref.toLowerCase().includes(st);
            const sku = product.SKU ? product.SKU.toLowerCase() : '';
            result = result || sku.toLowerCase().includes(st);
            return result;
        });
    }

    dispatch({
        type: PRODUCT_FILTERED,
        payload: result
    });
};

export const updateSearchText = searchText => (dispatch, getState) => {
    dispatch({type: SEARCH_UPDATED, payload: searchText});
    dispatch(filterProducts());
};

export const updateBrandFilter = brandFilter => (dispatch, getState) => {
    if (brandFilter) {
        dispatch({type: BRAND_FILTER_UPDATED, payload: brandFilter});
    }
    dispatch(filterProducts());
};

export const productsWithoutDesc = () => (dispatch, getState) => {
    let result = getState().products.data;
    result = getState().products.data.filter(e => e.description === null);
    dispatch({
        type: PRODUCT_FILTERED,
        payload: result
    });
}

export const productsSelected = selected => ({
    type: SELECTED_PRODUCTS,
    payload: selected
});

export const selectedProduct = id => (dispatch, getState) => {
    let selected = getState().products.selected.map(e => e);
    let newSelected = selected.filter(el => el !== id);

    selected = id
        ? selected.length
            ? newSelected.length === selected.length
                ? concatUniq([selected, [id]])
                : newSelected
            : [id]
        : [];
    dispatch(productsSelected(selected));
};
