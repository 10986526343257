import React from 'react'

import {Formik, Field,} from 'formik'
import {InputAdornment, TextField, Button, Typography} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import SearchIcon from '@material-ui/icons/Search'
import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'


const TextFieldComponent = ({form, field, ...props}) => {

    return (
        <TextField {...field} {...props}/>
    )

}

export const ReferenceInfoContent = ({field, initialData, render,title}) => {


    return <Formik
        initialValues={initialData}
        enableReinitialize
        render={({values,dirty}) => {

            console.log("verified values reference", values)
            return (
                <div>
                    <Typography variant={"headline"}>
                        {title}
                    </Typography>
                    <Field
                        component={TextFieldComponent}
                        label="Official Site"
                        name={field + ".official"}
                        fullWidth
                        margin="dense"
                        id="official"
                        InputProps={{
                            startAdornment: values[field].verified ? (
                                <InputAdornment position="start">
                                    <DoneIcon/>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="start">
                                    <Button> <SearchIcon/></Button>
                                </InputAdornment>
                            ),
                            endAdornment: values[field].officialUrl ? (
                                <InputAdornment position="end">
                                    <Button><a href={values[field].officialUrl} rel="noopener noreferrer" target="_blank"><LinkIcon/></a></Button>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="end">
                                    <Button> <LinkOffIcon/></Button>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        component={TextFieldComponent}
                        label="KAKAKU"
                        name={field + ".kakaku"}
                        fullWidth
                        margin="dense"
                        id="kakaku"
                        InputProps={{
                            startAdornment: values[field].verified ? (
                                <InputAdornment position="start">
                                    <DoneIcon/>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="start">
                                    <Button> <SearchIcon/></Button>
                                </InputAdornment>
                            ),
                            endAdornment: values[field].kakakuUrl ? (
                                <InputAdornment position="end">
                                    <Button> <a href={values[field].kakakuUrl} rel="noopener noreferrer" target="_blank"><LinkIcon/></a></Button>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="end">
                                    <Button> <LinkOffIcon/></Button>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        component={TextFieldComponent}
                        label="JomaShop"
                        name={field + ".jomashop"}
                        fullWidth
                        margin="dense"
                        id="jomashop"
                        InputProps={{
                            startAdornment: values[field].verified ? (
                                <InputAdornment position="start">
                                    <DoneIcon/>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="start">
                                    <Button> <SearchIcon/></Button>
                                </InputAdornment>
                            ),
                            endAdornment: values[field].jomashopUrl ? (
                                <InputAdornment position="end">
                                    <Button> <a href={values[field].jomashopUrl} rel="noopener noreferrer" target="_blank"><LinkIcon/></a></Button>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="end">
                                    <Button> <LinkOffIcon/></Button>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        component={TextFieldComponent}
                        label="PrestigeTime"
                        name={field + ".prestigetime"}
                        fullWidth
                        margin="dense"
                        id="prestigetime"
                        InputProps={{
                            startAdornment: values[field].verified ? (
                                <InputAdornment position="start">
                                    <DoneIcon/>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="start">
                                    <Button> <SearchIcon/></Button>
                                </InputAdornment>
                            ),
                            endAdornment: values[field].jomashopUrl ? (
                                <InputAdornment position="end">
                                    <Button> <a href={values[field].jomashopUrl} rel="noopener noreferrer" target="_blank"><LinkIcon/></a></Button>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="end">
                                    <Button> <LinkOffIcon/></Button>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        component={TextFieldComponent}
                        label="WatchMaxx"
                        name={field + ".watchmaxx"}
                        fullWidth
                        margin="dense"
                        id="watchmaxx"
                        InputProps={{
                            startAdornment: values[field].verified ? (
                                <InputAdornment position="start">
                                    <DoneIcon/>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="start">
                                    <Button> <SearchIcon/></Button>
                                </InputAdornment>
                            ),
                            endAdornment: values[field].jomashopUrl ? (
                                <InputAdornment position="end">
                                    <Button> <a href={values[field].jomashopUrl} rel="noopener noreferrer" target="_blank"><LinkIcon/></a></Button>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="end">
                                    <Button> <LinkOffIcon/></Button>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        component={TextFieldComponent}
                        label="WatchesOfMayFair"
                        name={field + ".watchesofmayfair"}
                        fullWidth
                        margin="dense"
                        id="watchesofmayfair"
                        InputProps={{
                            startAdornment: values[field].verified ? (
                                <InputAdornment position="start">
                                    <DoneIcon/>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="start">
                                    <Button> <SearchIcon/></Button>
                                </InputAdornment>
                            ),
                            endAdornment: values[field].jomashopUrl ? (
                                <InputAdornment position="end">
                                    <Button> <a href={values[field].jomashopUrl} rel="noopener noreferrer" target="_blank"><LinkIcon/></a></Button>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="end">
                                    <Button> <LinkOffIcon/></Button>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        component={TextFieldComponent}
                        label="HH"
                        name={field + ".HH"}
                        fullWidth
                        margin="dense"
                        id="hh"
                        InputProps={{
                            startAdornment: values[field].verified ? (
                                <InputAdornment position="start">
                                    <DoneIcon/>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="start">
                                    <Button> <SearchIcon/></Button>
                                </InputAdornment>
                            ),
                            endAdornment: values[field].HHUrl ? (
                                <InputAdornment position="end">
                                    <Button> <a href={values[field].HHUrl} rel="noopener noreferrer" target="_blank"><LinkIcon/></a></Button>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="end">
                                    <Button> <LinkOffIcon/></Button>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        component={TextFieldComponent}
                        label="VIP Station"
                        name={field + ".vip"}
                        fullWidth
                        margin="dense"
                        id="VIP"
                        InputProps={{
                            startAdornment: values[field].verified ? (
                                <InputAdornment position="start">
                                    <DoneIcon/>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="start">
                                    <Button> <SearchIcon/></Button>
                                </InputAdornment>
                            ),
                            endAdornment: values[field].vipUrl ? (
                                <InputAdornment position="end">
                                    <Button> <a href={values[field].vipUrl} rel="noopener noreferrer" target="_blank" ><LinkIcon/></a></Button>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="end">
                                    <Button> <LinkOffIcon/></Button>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        component={TextFieldComponent}
                        label="Watch Base"
                        name={field + ".watchbase"}
                        fullWidth
                        margin="dense"
                        id="watchbase"
                        InputProps={{
                            startAdornment: values[field].verified ? (
                                <InputAdornment position="start">
                                    <DoneIcon/>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="start">
                                    <Button> <SearchIcon/></Button>
                                </InputAdornment>
                            ),
                            endAdornment: values[field].watchbaseUrl ? (
                                <InputAdornment position="end">
                                    <Button> <a href={values[field].watchbaseUrl} rel="noopener noreferrer" target="_blank"><LinkIcon/></a></Button>
                                </InputAdornment>
                            ) : (
                                <InputAdornment position="end">
                                    <Button> <LinkOffIcon/></Button>
                                </InputAdornment>
                            )
                        }}
                    />

                    {render({values: values[field], field,dirty})}
                </div>
            )
        }
        }


    />
}