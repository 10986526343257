import React from 'react'

import {Formik, Field,} from 'formik'
import {Chip, InputAdornment, TextField, Typography} from "@material-ui/core";
import {SmartBox} from "@cosmos/chaos";


const TextFieldComponent = ({form, field, ...props}) => {

    return (
        <TextField {...field} {...props}/>
    )

}

let chipRenderer = (props) => {
    const {item, handleDelete} = props;
    console.log("item", item);
    return (
        <Chip
            key={item}
            tabIndex={-1}
            label={item}
            onDelete={handleDelete(item)}
        />
    );
}
export const CaseContent = ({field, initialData, render, title}) => {


    return <Formik
        initialValues={initialData}
        enableReinitialize
        render={({values, setFieldValue,dirty}) => {

            return (
                <div>
                    <Typography variant={"headline"}>
                        {title}
                    </Typography>
                    <SmartBox
                        isOpen={false}
                        multi
                        updateSearchText={(word) => setFieldValue(field + ".edit.materials", word)}
                        searchText={values[field].edit.materials}
                        suggestionProvider={(e) => [e]}
                        selectedItems={values[field].materials}
                        updateSelectedItems={(items) => setFieldValue(field + ".materials", items)}
                        chipRenderer={chipRenderer}
                        placeHolder="Materials"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".shape"}
                        label="Shape"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".crystal"}
                        label="Crystal"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".bezel"}
                        label="Bezel"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".back"}
                        label="Back"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".diameter"}
                        label="Diameter"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".height"}
                        label="Height"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".lugWidth"}
                        label="Lug Width"
                        fullWidth
                        margin="dense"
                    />


                    {render({values: values[field], field,dirty})}
                </div>)
        }
        }
    />
}