import React from "react";
import {IconButton, AppBar, Toolbar, Typography} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {shiftProduct} from "../../../../actions";
import styles from './styles.module.scss'

export const AppBarDialog = ({
                                 clone,
                                 product,
                                 ids,
                                 checkChevron,
                                 dispatch
                             }) => {
    return (
        <AppBar position={"relative"}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    onClick={() => {
                        dispatch(shiftProduct(product.id, -1));
                    }}
                    disabled={checkChevron(product.id).left}
                >
                    <ChevronLeftIcon/>
                </IconButton>
                <Typography variant="title" color="inherit">
                    {product && product.id > 0
                        ? `Product: ${product.id} - ${product.reference}`
                        : ids.length > 0 && !clone
                            ? `Batch Edit Products ${ids}`
                            : clone
                                ? `New Product: reference ${product.reference}`
                                : "New product"}
                </Typography>
                <IconButton
                    color="inherit"
                    onClick={() => {
                        dispatch(shiftProduct(product.id, 1));
                    }}
                    disabled={checkChevron(product.id).right}
                >
                    <ChevronRightIcon/>
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};
