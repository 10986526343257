import React, { Fragment } from "react";

import { GeneralInfoBottom } from "./GeneralInfoBottom";
import { GeneralInfoTop } from "./GeneralInfoTop";
import { CardContent, Card, CardMedia } from "@material-ui/core";
import Slider from "../../../../slider";
export const GeneralCard = ({
  values,
  classes,
  downshiftBrand,
  brands,
  setValues
}) => (
  <Card className={classes.contentCard}>
    <CardContent>
      <div className={classes.generalCard}>
        <div style={{ flex: "3" }}>
          <GeneralInfoTop
            classes={classes}
            values={values}
            setValues={setValues}
            brands={brands}
            downshiftBrand={downshiftBrand}
          />
        </div>
        <div className={classes.productViewer} style={{ flex: "2" }}>
          {values.assets.length > 0 ? (
            <Card>
              <Slider assets={values.assets} />
            </Card>
          ) : (
            <CardMedia
              component="img"
              className={classes.productMedia}
              image="/dummy.jpg"
            />
          )}
        </div>
      </div>
      <div className={classes.generalCard2}>
        <GeneralInfoBottom classes={classes} values={values} />
      </div>
    </CardContent>
  </Card>
);
