import React, { Component } from "react";

import { FormikTextField, CustomChipComponent } from "../customFields";
import { Field } from "formik";
import { Typography, CardContent, Card } from "@material-ui/core";

export const BandFragment = ({ disabled, values, classes }) => {
  return (
    <React.Fragment>
      <Field
        component={FormikTextField}
        name={"band.type"}
        label="Type"
        fullWidth
        margin="dense"
        id="band.type"
        disabled={disabled}
      />

      <CustomChipComponent
        classes={classes}
        label={"Materials"}
        value={values.band.materials}
        name={"band.materials"}
        disabled={disabled}
      />
      <Field
        component={FormikTextField}
        name={"band.buckle"}
        label="Buckle"
        fullWidth
        margin="dense"
        id="band.buckle"
        disabled={disabled}
      />
    </React.Fragment>
  );
};
