import {GraphQLClient} from "graphql-request";
import {system} from "../constants";
import {AbstractServices} from "@cosmos/chaos";

export class BrandService extends AbstractServices {
    initClient() {
        return new GraphQLClient(system.endpoint);
    }

    async getAllBrands() {
        const query = `query { 
            brands{
                id
                name
                logos {
                  fid
                  type
                  size
                }
            }
        }`;

        let results = await this.client.request(query);

        return results.brands;
    }

    //need to create this in graphql
    async getBrandLogo(id) {
        const variables = {filter: {id}};
        const query = `query { 
            brands(filter: $filter{
                id
                name
            }
        }`;

        let results = await this.client.request(query, variables);

        return results.brands;
    }
}
