import React, { Fragment } from "react";
import { FormikTextField, CustomCheckboxComponent } from "../customFields";
// import Fabbox from "../../../../Fabbox";
import { Field } from "formik";
import { Typography } from "@material-ui/core";
import { mapKeys } from "lodash";

export const GeneralInfoTop = ({
  classes,
  values,
  downshiftBrand,
  brands,
  setValues
}) => {
  return (
    <Fragment>
      <Typography variant="headline">General</Typography>

      {/*<Fabbox*/}
        {/*suggestions={downshiftBrand ? downshiftBrand : null}*/}
        {/*title={"Brands"}*/}
        {/*initialInputValue={values.brandID ? brands[values.brandID] : ""}*/}
        {/*onSelection={input => {*/}
          {/*let brandKey;*/}
          {/*mapKeys(brands, (val, el) => {*/}
            {/*if (input === brands[el]) {*/}
              {/*brandKey = el;*/}
            {/*}*/}
          {/*});*/}
          {/*if (brandKey) {*/}
            {/*setValues({ ...values, brandID: brandKey });*/}
          {/*}*/}
        {/*}}*/}
      {/*/>*/}
      <Field
        component={FormikTextField}
        name={"name"}
        id="name"
        label="Name"
        fullWidth
        margin="dense"
        required
        className={classes.formField}
      />

      <Field
        component={FormikTextField}
        name={"reference"}
        id="reference"
        label="Reference"
        fullWidth
        margin="dense"
        required
        className={classes.formField}
      />
      <div className={classes.formRow}>
        <Field
          component={FormikTextField}
          name={"dialColor"}
          id="color"
          label="Dial Color"
          fullWidth
          margin="dense"
          required
          className={classes.formField}
        />
        <Field
          component={FormikTextField}
          name={"gems"}
          id="gems"
          label="Gems"
          fullWidth
          margin="dense"
          required
          className={classes.formField}
        />
      </div>
      <div className={classes.formRow}>
        <Field
          component={FormikTextField}
          name={"waterResistance"}
          label="Water Resistance"
          margin="dense"
          fullWidth
          className={classes.formField}
        />
        <Field
          component={FormikTextField}
          name={"manufacturedFrom"}
          label="Manufactured From"
          fullWidth
          margin="dense"
          id="manufacturedFrom"
          className={classes.formField}
        />
      </div>
      <div className={classes.formRow2}>
        <Field
          component={CustomCheckboxComponent}
          name={"bundled"}
          label="Bundled"
          margin="dense"
          className={classes.formField}
          value={values.bundled ? "true" : "false"}
          checked={values.bundled}
        />

        <Field
          component={CustomCheckboxComponent}
          name={"limited"}
          label="Limited"
          margin="dense"
          value={values.limited ? "true" : "false"}
          className={classes.formField}
          checked={values.limited}
        />
      </div>
    </Fragment>
  );
};
