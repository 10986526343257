import React, { Component } from "react";

import { FormikTextField, CustomChipComponent } from "../customFields";
import { Field } from "formik";
import { Typography, CardContent, Card, Grid } from "@material-ui/core";
import { BandFragment } from "./BandFragment";
export const BandCard = ({ values, classes }) => {
  return (
    <Card className={classes.contentCard}>
      <CardContent>
        <Typography variant="headline">Band</Typography>
        <Grid container justfify="space-between" wrap="nowrap">
          <Grid item>
            <BandFragment disabled={true} values={values} classes={classes} />
          </Grid>
          <Grid item>
            <BandFragment disabled={false} values={values} classes={classes} />
          </Grid>
          <Grid item>
            <BandFragment disabled={true} values={values} classes={classes} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
