export const productSchema = {
  id: "",
  brandID: "",
  modelID: "",
  name: "",
  reference: "",
  dialColor: "",
  gems: "",
  waterResistance: "",
  bundled: "",
  limited: "",
  gender: "",
  style: [], //[string]
  feature: [], //[string]
  related: [], //[int]
  manufacturedFrom: "",
  manufacturedTo: "",
  box: "", //shippingInfo
  SKU: "",
  case: {
    //WatchCaseInput
    materials: [], //[string]
    crystal: "",
    bezel: "",
    back: "",
    diameter: "",
    height: "",
    lugWidth: ""
  },
  dial: {
    //WatchDialInput
    color: "",
    indexType: "",
    handStyle: "",
    subDial: "",
    gemSet: "",
    finish: ""
  },
  caliber: {
    //WatchMovementInput
    type: "",
    description: "",
    brand: "",
    reserve: "",
    reference: "",
    diameter: "",
    frequency: "",
    jewels: "",
    chronograph: "",
    hands: "",
    display: "",
    additional: ""
  },
  band: {
    //WatchBandInput
    type: "",
    materials: [], //[string]
    buckle: ""
  },
  ASIN: {
    //ChannelSKUInput
    ws: "",
    twc: ""
  },
  GTIN: {
    //ChannelSKUInput
    ws: "",
    twc: ""
  },
  description: "",
  search: "",
  path: "",
  external: {
    //ReferenceInfoInput
    official: "",
    kakaku: "",
    hh: "",
    watchbase: "",
    jomashop: "",
    uvkdk: "",
    vip: "",
    verified: "",
    verifiedBy: ""
  },
  assets: {
    type: "",
    status: "",
    reference: ""
  }
};
