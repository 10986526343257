import React, {Component} from "react";
import MobileStepper from "@material-ui/core/MobileStepper/MobileStepper";
import Button from "@material-ui/core/Button/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import styles from './slider.module.scss';

export class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
            translateValue: 0,
        }
    }

    goPrevSlide = () => {
        if (this.state.currentIndex === 0) {
            return this.setState({
                currentIndex: this.props.assets.length - 1,
                translateValue: -(this.props.assets.length - 1) * this.slideWidth()
            })
        }


        this.setState(prevState => ({
            currentIndex: prevState.currentIndex - 1,
            translateValue: prevState.translateValue + this.slideWidth()
        }));
    };

    goNextSlide = () => {
        if (this.state.currentIndex === this.props.assets.length - 1) {
            return this.setState({
                currentIndex: 0,
                translateValue: 0
            })
        }
        this.setState(prevState => ({
            currentIndex: prevState.currentIndex + 1,
            translateValue: prevState.translateValue + -(this.slideWidth())
        }));
    };

    handleDotClick = (i) => {
        if (i === this.state.currentIndex) return;
        this.setState({
            currentIndex: i,
            translateValue: -i * this.slideWidth()
        })

    };

    slideWidth = () => {
        return 364
    };

    render() {
        let {assets} = this.props;
        return (
            <div className={styles.slider}>
                <div className={styles.wrapper} style={{
                    transform: `translateX(${this.state.translateValue}px`,
                    transition: 'transform ease-out 0.45s',
                    width: (this.props.assets ? this.props.assets.length : 0) * this.slideWidth()
                }}>
                    {
                        this.props.assets &&
                        this.props.assets.map((image, i) => (
                            <div key={`image-${i}`} className={styles.slide}>
                                <img src={`/files/${image.reference}`} className={styles.picture}/>
                            </div>
                        ))
                    }
                </div>
                <MobileStepper
                    variant="dots"
                    steps={assets.length}
                    activeStep={this.state.currentIndex}
                    position="static"
                    nextButton={
                        <Button size="small"
                                color="primary"
                                onClick={this.goNextSlide}>
                            Next
                            <KeyboardArrowRight/>
                        </Button>
                    }
                    backButton={
                        <Button size="small"
                                color="primary"
                                onClick={this.goPrevSlide}>
                            <KeyboardArrowLeft/>
                            Back
                        </Button>
                    }
                />
            </div>
        );
    }
}


export default Slider;
