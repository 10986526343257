import {Checkbox, Paper, withStyles} from "@material-ui/core";
import React from "react";
import {connect} from "react-redux";
import {AutoSizer} from "react-virtualized";
import {Authorization, authorized, DataGrid, TableToolbar} from "@cosmos/chaos";
// import CopyIcon from "@material-ui/icons/FileCopy";

import {
    selectedProduct,
    checkTotalBox,
    editProduct,
    editProducts,
    loadBrands,
    loadProducts,
    filterProducts,
    cloneProduct,
    productsWithoutDesc,
    updateSearchText, editChannelProduct
} from "../../actions";
import Typography from "@material-ui/core/Typography/Typography";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button/Button";
import {ProductEditor, ProductEditors, ProductLanguageEditor} from "../../components/dialogs";
import BrandBox from "../../components/brandbox";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Input from "@material-ui/core/Input/Input";
import Switch from '@material-ui/core/Switch'
import {FilterBar} from "../../components/tableFilters";
import {ChannelProductEditor} from "../../components/dialogs/channelProduct";


const styles = theme => ({
    root: {
        flex: "1",
        display: "flex",
        flexDirection: "column"
    },
    feature: {
        flex: 1,
        display: "flex",
        flexDirection: "column"
    },
    content: {
        borderTop: "1px solid #eee",
        flex: 1
    },
    button: {},
    searchInput: {
        width: "100%",
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create("width")
    },
    products :{
    dataCell :{
        cellContents: {
        }
      },
    headerSortable: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        color: "#333"
      },
    
    headerSortableIndex: {
        display:"none",
        fontSize: "80%",
      },
    
    headerSortableActive: {
        color: "#000",
        '& .headerSortableIndex': {
            display: 'block'
        }
      },
    headerSortableIcon: {
        width: "12px",
        height: "12px",
        color: "#3333EE"
      }
    }
});

class ProductsView extends React.Component {
    state={
        orders: new Map()
    }
    constructor(props) {
        super(props);
       
        this.props.dispatch(loadBrands());
        this.props.dispatch(loadProducts());

        this.editProducts = this.editProducts.bind(this);
        this.editProduct = this.editProduct.bind(this);
        this.cloneProduct = this.cloneProduct.bind(this);
    }

    isAuthorize = authorized(['syno.admin', 'syno.edit']);

    refreshProducts = () => {
        this.props.dispatch(loadProducts());
    };

    checkFull = () => {
        let filteredProductsSelectedInCurrentTable = this.props.filtered.filter(
            el => this.props.selected.includes(el.id)
        );
        return this.props.filtered.length === 0
            ? true
            : filteredProductsSelectedInCurrentTable.length !==
            this.props.filtered.length;
    };

    editProducts = async () => {
        const {selected} = this.props;
        await this.props.dispatch(editProducts(selected));
    };

    editProduct = rowData => {
        const {selected} = this.props;
        // untick items if ticked

        if (selected.length > 0) {
            this.props.dispatch(selectedProduct(0));
        }
        this.props.dispatch(editProduct(rowData));
    };

    cloneProduct = rowData => {
        const {selected} = this.props;

        if (selected.length > 0) {
            this.props.dispatch(selectedProduct(0));
        }
        this.props.dispatch(cloneProduct(rowData));
    };

    handleSwitch = name => event =>  {
        console.log("Switch here", event.target.checked);
        // console.log("products here", this.props.product);
        if (event.target.checked) {
            this.props.dispatch(productsWithoutDesc());
        } else {
            this.props.dispatch(loadProducts());
        }
    }

    updateSorting(key) {
        let orders = this.state.orders;
        if (orders.has(key)) {
            const dir = orders.get(key);
            if (dir === 'asc') {
                orders.set(key, 'desc');
            } else {
                orders.delete(key);
            }
        } else {
            orders.set(key, 'asc');
        }
        this.setState({orders});
    }

    render() {
        const {classes, style, searchText, selected, filtered, brands, variant} = this.props;
        const {orders} = this.state;
        // if problem, then shift source of truth to be the state with getDerivedStateFromProps
        // const { selected, filtered } = this.state;
        const permitted = this.isAuthorize(this.props.permission);

        const handleFilter = changes => {
            let searchText = changes.target.value;
            this.props.dispatch(updateSearchText(searchText));
        };

        const mappedData = filtered.map(d => {
            const obj = {
                id: d.id,
                brand: brands[d.brandID],
                name: d.name,
                reference: d.reference,
                dialColor: d.dialColor,
                gems: d.gems,
                gender: d.gender,
                style: d.style,
                feature: d.feature
            }
            return obj;
        })

        const columns = [
            {
                name: "edit",
                header: "",
                cell: rowData => (
                    <Toolbar>
                        <IconButton
                            aria-label="Edit"
                            color="primary"
                            className={classes.button}
                            onClick={() => this.editProduct(rowData)}
                        >
                            <EditIcon/>
                        </IconButton>
                        {/*
                          <IconButton
                            aria-label="Clone"
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                              if (selected.length > 0) {
                                this.props.dispatch(selectedProduct(0));
                              }
                              this.props.dispatch(cloneProduct(rowData));
                            }}
                          >
                            <CopyIcon />
                          </IconButton>
                          */}
                    </Toolbar>
                ),
                cellProps: {
                    style: {paddingLeft: 0, paddingRight: 0}
                },
                width: 120
            },
            {
                name: "id",
                header: "ID",
                cellProps: {style: {paddingRight: 0}},
                width: 96,
                onHeaderClick: () => {
                    this.updateSorting('id');
                }
            },
            {
                name: "brand",
                header: "Brand",
                cell: rowData => <Typography variant={variant} className={styles.title}>{rowData.brand}</Typography>,
                width: 160,
                onHeaderClick: () => {
                    this.updateSorting('brand');
                }
            },
            {name: "name", header: "Name", 
            onHeaderClick: () => {
                this.updateSorting('name');
            }},
            {name: "reference", header: "Reference", width: 180,
            onHeaderClick: () => {
                this.updateSorting('reference');
            }},
            {name: "dialColor", header: "Color", width: 120,
            onHeaderClick: () => {
                this.updateSorting('dialColor');
            }},
            {name: "gems", header: "Gems", width: 120,
            onHeaderClick: () => {
                this.updateSorting('gems');
            }},
            {name: "gender", header: "Gender", width: 120,
            onHeaderClick: () => {
                this.updateSorting('gender');
            }},
            {name: 'style', header: 'Style', width: 180,
            onHeaderClick: () => {
                this.updateSorting('style');
            }},
            {name: 'feature', header: 'Feature',
            onHeaderClick: () => {
                this.updateSorting('feature');
            }}
        ];
        if(permitted){
            columns.unshift({
                name: "checkbox",
                header: (
                    <Authorization allowed={['syno.admin', 'syno.edit']}>
                        {({permit})=>(
                            permit(this.props.permission) &&
                            <Checkbox
                                checked={selected.length > 0}
                                onChange={e => this.props.dispatch(checkTotalBox())}
                                {...selected.length > 0 &&
                                this.checkFull() && {
                                    indeterminate: true,
                                    color: "default"
                                }}
                            />
                        )
                        }
                    </Authorization>
                )
                ,
                cell: rowData => (
                    <Checkbox
                        checked={selected.some(id => rowData.id === id)}
                    />
                ),
                cellProps: {style: {paddingRight: 0}},
                width: 72
            })
        }

        return (
            <div className={classes.root} style={{...style}}>
                <Paper className={classes.feature}>
                    <TableToolbar
                        numSelected={selected.length}
                        title="Products"
                        refreshTrigger={this.refreshProducts}
                        // newItemTrigger={this.newProduct}
                        searchBox={() => (
                            <Input
                                placeholder="Model Reference..."
                                disableUnderline
                                value={searchText}
                                onChange={handleFilter}
                                className={classes.searchInput}
                                fullWidth
                            />
                        )}
                        toolBox={() => (
                            <Authorization allowed={['syno.admin', 'syno.edit']}>
                                {({ permit }) => (
                                    !permit(this.props.permission) &&
                                    <Switch
                                        onChange={this.handleSwitch('checkedA')}
                                    />
                                )}
                            </Authorization>
                        )}
                        filterBox={() => <FilterBar/>}
                        selectedItemHandler={() => (
                            <div>
                                <Button
                                    onClick={this.editProducts}
                                    color="primary"
                                    disabled={selected.length ? false : true}
                                >
                                    <EditIcon/>
                                </Button>
                            </div>
                        )}
                    />
                    {this.props.loading && <LinearProgress/>}
                    <div className={classes.content}>
                        <AutoSizer>
                            {({width, height}) => (
                                <DataGrid
                                    data={mappedData}
                                    columns={columns}
                                    isCellSelected={(column, rowData) => {
                                        return selected.indexOf(rowData.id) > -1;
                                    }}
                                    isCellHovered={(
                                        column,
                                        rowData,
                                        hoveredColumn,
                                        hoveredRowData
                                    ) => {
                                        return rowData && rowData.id === hoveredRowData.id;
                                    }}
                                    onCellClick={(column, rowData) => {
                                        if (column.name === "edit" || column.name === "tool" || !permitted)
                                            return;
                                        this.props.dispatch(selectedProduct(rowData.id));
                                    }}
                                    width={width}
                                    maxHeight={height}
                                    includeHeaders={true}
                                    fixedRowCount={1}
                                    orderBy={orders}
                                    classes={{
                                        cell: styles.dataCell,
                                        cellContents: styles.cellContents,
                                        sortableHeader: styles.headerSortable,
                                        sortableHeaderActive: styles.headerSortableActive,
                                        sortableHeaderIcon: styles.headerSortableIcon,
                                        sortIndex: styles.headerSortableIndex
                                    }}
                                />
                            )}
                        </AutoSizer>
                    </div>
                </Paper>
                {this.props.target ? (
                    this.props.language ? (
                        <ProductLanguageEditor/>
                    ) : (
                        <Authorization allowed={['syno.admin', 'syno.edit']}>
                            {({permit}) => (
                                permit(this.props.permission) ?
                                    <ProductEditor/> :
                                    <ChannelProductEditor/>
                            )}
                        </Authorization>
                    )
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(
    connect(state => ({
        permission: state.auth.permission,
        brands: state.products.brands,
        loading: state.products.loading,
        error: state.products.error,
        products: state.products.data,
        filtered: state.products.filtered,
        selected: state.products.selected,
        searchText: state.products.searchText,
        target: state.products.target,
        language: state.productsLanguage.language
    }))(ProductsView)
);
