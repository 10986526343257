import React, { Fragment } from "react";

import { FormikTextField } from "../customFields";
import { Field } from "formik";
import {
  Typography,
  Select,
  MenuItem,
  Card,
  CardContent,
  InputLabel
} from "@material-ui/core";

const CustomSelectChannelComponent = props => (
  <Fragment>
    <InputLabel htmlFor="channel">Channel</InputLabel>

    <Select value={props.value} onChange={props.handleChange}>
      <MenuItem value="ws">WS</MenuItem>
      <MenuItem value="twc">TWC</MenuItem>
    </Select>
  </Fragment>
);

export class SalesCard extends React.Component {
  state = {
    channel: "twc"
  };

  render() {
    const { channel } = this.state;
    const { values, classes } = this.props;

    return (
      <Card className={classes.contentCard}>
        <CardContent>
          <div className={classes.saleswrap}>
            <Typography variant="headline">Sales</Typography>
            <CustomSelectChannelComponent
              value={channel}
              handleChange={e => this.setState({ channel: e.target.value })}
            />
          </div>
          <Field
            component={FormikTextField}
            label="SKU"
            name={"SKU"}
            fullWidth
            margin="dense"
            id="SKU"
          />
          {["twc", "ws"].map((el, index) => {
            if (el === channel) {
              return (
                <Fragment key={index}>
                  <Field
                    component={FormikTextField}
                    label="ASIN"
                    name={`ASIN.${channel}`}
                    fullWidth
                    margin="dense"
                    id={`ASIN.${channel}`}
                  />
                  <Field
                    component={FormikTextField}
                    label="GTIN"
                    name={`GTIN.${channel}`}
                    fullWidth
                    margin="dense"
                    id={`GTIN.${channel}`}
                  />
                </Fragment>
              );
            }
          })}
        </CardContent>
      </Card>
    );
  }
}
