import React from "react";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button/Button";
import Filter from '@material-ui/icons/FilterList'
import Grow from "@material-ui/core/Grow/Grow";
import {BrandFilter} from "./brand";

import styles from './filters.module.scss';

class FilterUnit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    toggleFilter = () => {
        this.setState(prevState => ({open: !prevState.open}))
    };

    render() {
        return (
            <div className={styles.root}>
                {
                    this.state.open &&
                    <Grow appear in={true} timeout={300}>
                        <div className={styles['filter-bar']}>
                            <BrandFilter/>
                        </div>
                    </Grow>
                }
                <Button variant="fab"
                        color={"primary"}
                        mini
                        onClick={this.toggleFilter}
                >
                    <Filter/>
                </Button>

            </div>
        )
    }
}

export const FilterBar = connect(state => ({
    brands: state.products.brands
}))(FilterUnit);