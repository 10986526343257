import React from 'react'
import styles from './styles.module.scss'
import {Formik, Field,} from 'formik'
import {CardContent, InputAdornment, TextField, Select, MenuItem, Typography} from "@material-ui/core";

const TextFieldComponent = ({form, field, ...props}) => {

    return (
        <TextField {...field} {...props}/>
    )
}

export const SalesContent = ({field, initialData, render, title}) => {

    return (<Formik
        initialValues={initialData}
        enableReinitialize
        render={({values, setFieldValue,dirty}) => {

            let channel = values[field].edit.channel
            return (
                <div>
                    <div className={styles.flexRow}>
                        <Typography variant={"headline"}>
                            {title}
                        </Typography>
                        <Select
                            onChange={(e) => {
                                setFieldValue(field + ".edit.channel", e.target.value)

                            }}
                            value={channel}
                        >
                            <MenuItem value={"twc"}>
                                <Typography>
                                    TWC
                                </Typography>
                            </MenuItem>
                            <MenuItem value={"ws"}>
                                <Typography>
                                    WS
                                </Typography>
                            </MenuItem>
                        </Select>
                    </div>


                    <Field
                        component={TextFieldComponent}
                        name={field + ".SKU"}
                        label="SKU"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={channel === 'ws' ? field + ".ASIN.ws" : field + ".ASIN.twc"}
                        label="ASIN"
                        fullWidth
                        margin="dense"
                        key={field + channel + 'ASIN'}
                    />
                    {/*<SmartBox multi={false} searchText={} updateSearchText={(array)=>array.push()} suggestionProvider={}/>*/}
                    <Field
                        component={TextFieldComponent}
                        name={channel === 'ws' ? field + ".GTIN.ws" : field + ".GTIN.twc"}
                        label="GTIN"
                        fullWidth
                        margin="dense"
                        key={field + channel + 'GTIN'}
                    />
                    {render({values: values[field], field,dirty})}
                </div>
            )
        }
        }
    />)
}