import {
  uniq,
  concat,
  flattenDeep,
  pickBy,
  defaults,
  isObject,
  isArray,
  isNil
} from "lodash";

export const filterOutNonEmptyFields = obj => {
  let newObject;
  let newObjectNested = {};
  if (isObject(obj) && !isArray(obj)) {
    newObject = pickBy(
      obj,
      (val, key) =>
        isObject(val) && !isArray(val)
          ? (newObjectNested[key] = filterOutNonEmptyFields(val))
          : isNil(val)
            ? false
            : val !== ""
    );
    return defaults(newObjectNested, newObject);
  }
};

export const concatUniq = arrays => {
  return uniq(flattenDeep(concat(...arrays)));
};
