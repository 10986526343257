import React, {Fragment} from 'react'
import {Formik, Field,} from 'formik'
import {
    InputAdornment,
    TextField,
    Checkbox,
    FormControlLabel,
    Chip,
    Select,
    MenuItem,
    Typography
} from "@material-ui/core";
import {SmartBox} from '@cosmos/chaos'
import styles from './styles.module.scss'

const TextFieldComponent = ({form, field, ...props}) => {

    return (
        <TextField {...field} {...props}/>
    )

}
const TextAreaComponent = ({form, field, ...props}) => {
    return <TextField {...field} {...props} multiline rows={8} rowsMax={15}/>
}
const CheckBoxComponent = ({field, form, ...props}) => {
    const {value, ...rest} = field
    return (<Fragment>
        <FormControlLabel
            control={<Checkbox {...rest} {...props} />}
            label={props.label}
        />
    </Fragment>)
}


export const GeneralTopDetailsContent = ({field, initialData, render}) => {


    return <Formik
        initialValues={initialData}
        enableReinitialize
        render={({values, setFieldValue,dirty}) => {

            return (
                <div className={styles.flexCol}>
                    <div className={styles.flexRowBetween}>
                        <div className={[styles.flexCol, styles.item].join(" ")}>
                            <Field
                                component={TextFieldComponent}
                                label="Dial Color "
                                name={field + ".dialColor"}
                                fullWidth
                                margin="dense"
                                required
                            />
                            <Field
                                component={TextFieldComponent}
                                label="Water Resistance "
                                name={field + ".waterResistance"}
                                fullWidth
                                margin="dense"
                            />
                        </div>
                        <div className={[styles.flexCol, styles.item].join(" ")}>
                            <Field
                                component={TextFieldComponent}
                                label="Gems"
                                name={field + ".gems"}
                                fullWidth
                                margin="dense"
                                required
                            />
                            <Field
                                component={TextFieldComponent}
                                label="Manufactured From "
                                name={field + ".manufacturedFrom"}
                                fullWidth
                                margin="dense"
                            />
                        </div>
                    </div>
                    <div className={[styles.flexRow, styles.rowDetail].join(" ")}>
                        <Field
                            component={CheckBoxComponent}
                            label="Bundled"
                            name={field + ".bundled"}
                            onChange={(e) => {
                                setFieldValue(field + ".bundled", e.target.checked)
                            }}
                            margin="dense"
                            checked={values[field].bundled}
                        />
                        <Field
                            component={CheckBoxComponent}
                            label="Limited"
                            name={field + ".limited"}
                            onChange={(e) => {
                                setFieldValue(field + ".limited", e.target.checked)
                            }}
                            margin="dense"
                            checked={values[field].limited}
                        />
                    </div>
                    {render({values: values[field], field,dirty})}
                </div>
            )
        }}
    />
}

let chipRenderer = (props) => {
    const {item, handleDelete} = props;
    console.log("item", item);
    return (
        <Chip
            key={item}
            tabIndex={-1}
            label={item}
            onDelete={handleDelete(item)}
        />
    );
}


let checkGender = (gender) => {
    if(gender === ""){
        return ""
    }
    if (gender === "M" || gender === "F" || gender === "X") {
        return gender
    }
    if (gender === "Men's" || gender.toLowerCase() === "m" || gender.toLowerCase() === "men" || gender.toLowerCase() === "men's") {
        return "M"
    }
    if (gender === "Ladies" || gender.toLowerCase() === "w" || gender.toLowerCase() === "f") {
        return "F"
    }
    if (gender === "unisex" || gender.toLowerCase() === "u" || gender.toLowerCase() === "x") {
        return "X"
    }

}
export const GeneralBottomDetailsContent = ({field, initialData, render}) => {


    return <Formik
        initialValues={initialData}
        enableReinitialize
        render={({values, setFieldValue,dirty}) => {

            let gender = checkGender(values[field].gender)
            return (
                <div>
                    <Select
                        onChange={(e) => {
                            setFieldValue(field + ".gender", e.target.value)
                        }}
                        value={gender}
                    >
                        <MenuItem value={"M"}>
                            <Typography>
                                Men's
                            </Typography>
                        </MenuItem>
                        <MenuItem value={"F"}>
                            <Typography>
                                Ladies
                            </Typography>
                        </MenuItem>
                        <MenuItem value={"X"}>
                            <Typography>
                                Unisex
                            </Typography>
                        </MenuItem>
                    </Select>

                    <SmartBox
                        isOpen={false}
                        multi
                        updateSearchText={(word) => setFieldValue(field + ".edit.style", word)}
                        searchText={values[field].edit.style}
                        suggestionProvider={(e) => [e]}
                        selectedItems={values[field].style}
                        updateSelectedItems={(items) => setFieldValue(field + ".style", items)}
                        chipRenderer={chipRenderer}
                        placeHolder={"Styles"}
                    />
                    <SmartBox
                        multi
                        updateSearchText={(word) => setFieldValue(field + ".edit.feature", word)}
                        searchText={values[field].edit.feature}
                        suggestionProvider={(e) => [e]}
                        updateSelectedItems={(items) => setFieldValue(field + ".feature", items)}
                        selectedItems={values[field].feature}
                        isOpen={false}
                        chipRenderer={chipRenderer}
                        placeHolder={"Features"}
                    />
                    <SmartBox
                        isOpen={false}
                        placeHolder={"Related"}
                        multi
                        updateSearchText={(word) => setFieldValue(field + ".edit.related", word)}
                        searchText={values[field].edit.related}
                        suggestionProvider={(e) => [e]}
                        selectedItems={values[field].related}
                        updateSelectedItems={(items) => setFieldValue(field + ".related", items)}
                        chipRenderer={chipRenderer}
                    />

                    <Field
                        component={TextAreaComponent}
                        label="Description"
                        name={field + ".description"}
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        label="Path"
                        name={field + ".path"}
                        fullWidth
                        margin="dense"
                    />
                    {render({values: values[field], field,dirty})}
                </div>
            )
        }
        }
    />
}

