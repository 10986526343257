import React from 'react'

import {Formik, Field,} from 'formik'
import {InputAdornment, TextField, Typography, MenuItem} from "@material-ui/core";
import styles from './styles.module.scss'
import {SmartBox} from '@cosmos/chaos'
import util from 'util';

const TextFieldComponent = ({form, field, ...props}) => {

    return (
        <TextField {...field} {...props}/>
    )

}


let suggestionProvider = ({val, brands}) => {
    // console.log("val", val);
    let va = (typeof val === 'string')? val: val.label;

    return Object.values(brands).filter(el =>
        el.toLowerCase().includes(va.toLowerCase())
    ).map(el => ({label: el}))
}

export const GeneralInfoContent = ({field, initialData, render, brands, title}) => {

    console.log("brands", brands)
    return <Formik
        initialValues={initialData}
        enableReinitialize
        render={({values, setFieldValue,dirty}) => {

            console.log("values", values)
            return (
                <div className={styles.flexCol}>
                    <Typography variant={"headline"}>
                        {title}
                    </Typography>
                    {/*<BrandIDSelector brandID={brands[values[field].brandID]} brands={brands}/>*/}
                    <SmartBox
                        multi={false}
                        required
                        placeHolder={"Brand"}
                        searchText={util.inspect(values[field].brand)}
                        updateSearchText={(e) => {
                            console.log("updated search", e)
                            setFieldValue(field + ".brand", e)
                            Object.keys(brands).filter(i => e === brands[i]).map(index => {
                                if (index) {
                                    setFieldValue(field + ".brandID", Number(index))
                                }
                            })
                        }}
                        suggestionProvider={(val) => suggestionProvider({val, brands})}
                        updateSelectedItems={(e) => console.log("selected", e)}

                    />

                    <Field
                        component={TextFieldComponent}
                        label="Name "
                        name={field + ".name"}
                        fullWidth
                        margin="dense"
                        required
                    />
                    <Field
                        component={TextFieldComponent}
                        label="Reference"
                        name={field + ".reference"}
                        fullWidth
                        margin="dense"
                        required
                    />
                    {render({values: values[field], field,dirty})}

                </div>
            )
        }}
    />
}