import React from 'react'

import {Formik, Field,} from 'formik'
import {CardContent, InputAdornment, TextField, Typography} from "@material-ui/core";


const TextFieldComponent = ({form, field, ...props}) => {

    return (
        <TextField {...field} {...props}/>
    )

}

export const ShippingContent = ({field, initialData, render, title}) => {


    return <Formik
        initialValues={initialData}
        enableReinitialize
        render={({values,dirty}) => {

            return (
                <div>
                    <Typography variant={"headline"}>
                        {title}
                    </Typography>
                    <Field
                        component={TextFieldComponent}
                        name={field + ".box"}
                        label="Box"
                        fullWidth
                        margin="dense"
                        id="shipping.box"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".HS"}
                        label="HS Code"
                        fullWidth
                        margin="dense"
                        id="shipping.hsCode"
                    />
                    {render({values: values[field], field,dirty})}
                </div>
            )
        }
        }
    />
}