import React, { Component } from "react";

import { Select } from "@material-ui/core";
import { connect } from "react-redux";
import { languageSelected } from "../../../actions";

class LanguageComponent extends Component {
  state = {
    languages: {
      JPN: "Japanese Language",
      FR: "French"
    },
    selected: ""
  };

  handleChange = e => {
    this.setState({ selected: e.target.value });
    this.props.dispatch(languageSelected(e.target.value));
  };
  render() {
    const { languages, selected } = this.state;
    return (
      <Select value={selected} onChange={this.handleChange}>
        <option value="" />
        {languages &&
          Object.keys(languages).map(el => (
            <option value={el}>{languages[el]}</option>
          ))}
      </Select>
    );
  }
}

export const LanguageSelection = connect()(LanguageComponent);
