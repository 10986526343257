import React, { Fragment } from "react";
import {
  FormikTextField,
  CustomChipComponent,
  CustomTextAreaComponent,
  CustomSelectGenderComponent
} from "../customFields";
import { Field } from "formik";
import { Typography, CardContent, Card } from "@material-ui/core";

export const GeneralInfoBottom = ({ classes, values }) => {
  return (
    <Fragment>
      <Field
        component={CustomSelectGenderComponent}
        name={"gender"}
        label="Gender"
        fullWidth
        margin="dense"
        id="gender"
        classes={classes}
        value={values ? (values.gender ? values.gender : "") : ""}
      />

      <CustomChipComponent
        classes={classes}
        label={"Style"}
        name={"style"}
        value={values.style}
      />
      <CustomChipComponent
        classes={classes}
        label={"Features"}
        name={"feature"}
        value={values.feature}
      />
      <CustomChipComponent
        classes={classes}
        label={"Related"}
        name={"related"}
        value={values.related}
      />

      <Field
        component={CustomTextAreaComponent}
        name={"description"}
        label="Description"
        fullWidth
        margin="dense"
        id="description"
        className={classes.formField}
      />
      <Field
        component={FormikTextField}
        name={"path"}
        label="Path"
        fullWidth
        margin="dense"
        id="path"
        className={classes.formField}
      />
    </Fragment>
  );
};
