import React from 'react'

import {Formik, Field,} from 'formik'
import {InputAdornment, TextField, Typography} from "@material-ui/core";



const TextFieldComponent = ({form, field, ...props}) => {

    return (
        <TextField {...field} {...props}/>
    )

}

export const DialContent = ({field, initialData, render,title}) => {


    return <Formik
        initialValues={initialData}
        enableReinitialize
        render={({values,dirty}) => {

            return (
                <div>
                    <Typography variant={"headline"}>
                        {title}
                    </Typography>
                    <Field
                        component={TextFieldComponent}
                        name={field + ".color"}
                        label="Color"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".indexType"}
                        label="Index Type"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".handStyle"}
                        label="Hand Style"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".subDial"}
                        label="Sub Dial"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".gemSet"}
                        label="Gem Set"
                        fullWidth
                        margin="dense"
                    />
                    <Field
                        component={TextFieldComponent}
                        name={field + ".finish"}
                        label="Finish"
                        fullWidth
                        margin="dense"
                    />
                    {render({values: values[field], field,dirty})}
                </div>
            )
        }
        }
    />
}
