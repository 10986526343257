import React from "react";
import {render} from "react-dom";
import App from "./containers/app";
import {Provider} from "react-redux";
import {ConnectedRouter} from 'connected-react-router';
import store, {history} from "./store/index";

import "./index.scss";
import {createMuiTheme} from "@material-ui/core";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {blue, indigo, red} from "@material-ui/core/colors";
import {KeyScanner} from "@cosmos/chaos";

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: indigo,
        warning: red
    }
});

render(
    <KeyScanner>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <MuiThemeProvider theme={theme}>
                    <App/>
                </MuiThemeProvider>
            </ConnectedRouter>
        </Provider>
    </KeyScanner>
    ,

    document.querySelector("#root")
);
