import React from 'react'
import {AppBarDialog} from "./appbar";
import {productSchema} from '../productSchema'
import {Button, Dialog, DialogContent, DialogActions, Card, CardContent} from '@material-ui/core'
import {
    GeneralInfoContent,
    GeneralBottomDetailsContent,
    GeneralTopDetailsContent,
    SalesContent,
    ReferenceInfoContent,
    DialContent,
    CaseContent,
    CaliberContent,
    BandContent,
    ShippingContent,
} from './contentBoxes'
import {cancelEdit, createProduct, updateProduct, updateProducts} from "../../../actions";
import {defaultsDeep} from 'lodash'
import {connect} from 'react-redux'
import styles from './styles.module.scss'
import Slider from "../../slider";

class Editor extends React.Component {

    captureValues = ({values, field,dirty}) => {
        this[field] = values
        this.dirty={[field]:dirty}
    }

    handleSubmit = async () => {
        console.log(
            "submitting"
        )
        const {dispatch, clone, product, multi, ids} = this.props
        const {shippingContent, caliberContent,salesContent, generalInfoContent, generalTopDetailsContent, generalBottomDetailsContent, referenceInfoContent, bandContent, caseContent, dialContent} = this


        //delete helper fields for the smartbox
        //
        // delete generalInfoContent.brand
        // delete generalBottomDetailsContent.edit
        // delete bandContent.edit
        // delete caseContent.edit
        // delete salesContent.edit

        let values = {
            ...generalInfoContent,
            ...generalTopDetailsContent,
            ...generalBottomDetailsContent,
            ...shippingContent,
            ...salesContent
        }


         values ={
            ...values,
            external: referenceInfoContent,
            band: bandContent,
            case: caseContent,
            dial: dialContent,
            caliber: caliberContent,
            id: Number(product.id)
        }

        values = JSON.parse(JSON.stringify(values))
        delete values.edit;
        delete values.brand;
        delete values.band.edit;
        delete values.case.edit;

        console.log("SUBMITTING");
        let submit;

//create cloned Product
        if (clone && product.id === -1) {
            console.log("cloning submitted");
            submit = createProduct(values);
        }
//create product cloned or not
        if (!multi && !clone && product && product.id < 0) {
            console.log("creation submitted");
            submit = createProduct(values);
        }
//batchEditing --updateBatchProduct
        if (multi && ids.length > 0 && product.id < 0) {
            console.log("update batch submitted");
            submit = updateProducts({...values, id: ids});
        }
//update product
        if (product && product.id > 0) {
            console.log("update submitted");
            submit = updateProduct(values);
        }

        await dispatch(submit);

    }

    prepareData = (product, field) => {
        return JSON.parse(JSON.stringify({[field]: product}))
    }
    dialogOnClose = () => {

        this.props.dispatch(cancelEdit());
    };
    checkChevron = id => {
        const {products} = this.props
        let count = products.length - 1;
        let index;
        if (id < 0) {
            products.some((el, now) => {
                if (el.id === id) {
                    index = now;
                }
            });
        }
        return {
            left: id < 0 ? true : index === 0,
            right: id < 0 ? true : count === index
        };
    };


    render() {
        const {dialogOpen, product, dispatch, ids, clone, brands, ...props} = this.props

        console.log("product", product)

        return (<Dialog open={dialogOpen} onClose={this.dialogOnClose} maxWidth={"md"}>
            {product && <React.Fragment>
                <AppBarDialog
                    {...this.props}
                    checkChevron={this.checkChevron}
                />

                <DialogContent className={styles.contentBody}>

                    <Card className={styles.contentCard}>
                        <CardContent>
                            <div className={styles.flexRowBetween}>
                                <div className={[styles.flexColBetween, styles.widthGeneralDetail].join(" ")}>
                                    <GeneralInfoContent
                                        title={"General"}
                                        field={"generalInfoContent"} initialData={this.prepareData({
                                        name: product.name,
                                        reference: product.reference,
                                        brandID: product.brandID,
                                        brand: brands[product.brandID]||""
                                    }, "generalInfoContent")} brands={brands} render={this.captureValues}/>
                                    <GeneralTopDetailsContent field={"generalTopDetailsContent"}
                                                              initialData={this.prepareData({
                                                                  // edit: {bundled: "",limited: ""},
                                                                  dialColor: product.dialColor,
                                                                  gems: product.gems,
                                                                  waterResistance: product.waterResistance,
                                                                  manufacturedFrom: product.manufacturedFrom,
                                                                  bundled: product.bundled,
                                                                  limited: product.limited
                                                              }, "generalTopDetailsContent")}
                                                              render={this.captureValues}
                                    />
                                </div>
                                <div className={styles.productViewer}>
                                    {product.assets.length > 0 && (
                                        <Card>
                                            <Slider assets={product.assets}/>
                                        </Card>
                                    )}
                                </div>

                            </div>

                            <div>
                                <GeneralBottomDetailsContent
                                    field={"generalBottomDetailsContent"}
                                    initialData={this.prepareData({
                                        edit: {style: "", feature: "", related: ""},
                                        description: product.description,
                                        path: product.path,
                                        style: product.style,
                                        related: product.related,
                                        gender: product.gender,
                                        feature: product.feature,
                                    }, "generalBottomDetailsContent")}
                                    render={this.captureValues}
                                />
                            </div>
                        </CardContent>
                    </Card>
                    <div className={styles.flexRow}>
                        <Card className={styles.contentCard}>
                            <CardContent>
                                <SalesContent
                                    title={"Sales"}
                                    field={"salesContent"}
                                    initialData={this.prepareData({
                                        edit:{channel:'ws'},
                                        SKU: product.SKU,
                                        GTIN: product.GTIN,
                                        ASIN: product.GTIN
                                    }, "salesContent")}
                                    render={this.captureValues}/>
                            </CardContent>
                        </Card>
                        <Card className={styles.contentCard}>
                            <CardContent>
                                <ReferenceInfoContent
                                    title={"Reference Info"}
                                    field={"referenceInfoContent"}
                                    initialData={this.prepareData(product.external, "referenceInfoContent")}
                                    render={this.captureValues}/>
                            </CardContent>
                        </Card>
                    </div>
                    <div className={styles.flexRow}>
                        <Card className={styles.contentCard}>
                            <CardContent>
                                <DialContent
                                    title={"Dial"}
                                    field={"dialContent"}
                                    initialData={this.prepareData(product.dial, "dialContent")}
                                    render={this.captureValues}/>
                            </CardContent>
                        </Card>
                        <Card className={styles.contentCard}>
                            <CardContent>
                                <CaseContent
                                    title={"Case"}
                                    field={"caseContent"}
                                    initialData={this.prepareData({edit: {materials: ""}, ...product.case}, "caseContent")}
                                    render={this.captureValues}/>
                            </CardContent>
                        </Card>
                    </div>

                    <div className={styles.flexRow}>
                        <Card className={styles.contentCard}>
                            <CardContent>
                                <CaliberContent
                                    title={"Caliber"}
                                    field={"caliberContent"}
                                    initialData={this.prepareData(product.caliber, "caliberContent")}
                                    render={this.captureValues}/>
                            </CardContent>
                        </Card>
                        <div>
                            <Card className={styles.contentCard}>
                                <CardContent>
                                    <BandContent
                                        title={"Band"}
                                        field={"bandContent"}
                                        initialData={this.prepareData({edit: {materials: ""}, ...product.band}, "bandContent")}
                                        render={this.captureValues}/>
                                </CardContent>
                            </Card>
                            <Card className={styles.contentCard}>
                                <CardContent>
                                    <ShippingContent
                                        title={"Shipping"}
                                        field={"shippingContent"}
                                        initialData={this.prepareData({
                                            box: product.box,
                                            HS: product.HS
                                        }, "shippingContent")}
                                        render={this.captureValues}/>
                                </CardContent>
                            </Card>
                        </div>
                    </div>


                </DialogContent>
            </React.Fragment>
            }
            <DialogActions>
                <Button onClick={this.dialogOnClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={this.handleSubmit}
                    // disabled={this.isDirty()}
                    color="primary"
                >
                    {product.id > 0
                        ? "Update"
                        : ids.length > 0 && !clone
                            ? `Batch Edit- ${ids}`
                            : clone
                                ? `create from reference ${product.reference}`
                                : "Create"}
                </Button>
            </DialogActions>

        </Dialog>)
    }
}


export const ProductEditor = connect(state => {

        let product = defaultsDeep(state.products.target, productSchema);
        return ({
                product,
                dialogOpen: state.products.dialog,
                multi:
                state.products.multi,
                brands:
                state.products.brands,
                downshiftBrand:
                    state.products.downshiftBrand
                        ? Object.keys(state.products.brands).map(el => state.products.brands[el])
                        : [],
                ids:
                state.products.ids,
                clone:
                state.products.clone,
                chevronDialogOpen:
                state.products.chevronDialogOpen,
                products:
                state.products.filtered
            }
        )
    }
)(Editor)